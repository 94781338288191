import { Card, Col, Modal, Row } from "react-bootstrap"
import { updateProperties } from "../../redux/reducers/propertiesReducer"
import { useDispatch } from "react-redux"

const EditBox = ({ name, location, picture, area, quantity, handleAdd, handleRemove }) => {
  return (
    <Card>
      <Card.Body className="d-flex gap-3 align-items-start p-2">
        <img src={picture} width={90} height={90} alt="" />
        <section className="d-flex justify-content-between align-items-center  w-100">
          <div>
            <h6 className="fs-5 fw-medium mb-1" color="#3D3D3D">{name}</h6>
            <p className="smaller-text mb-1">{location}</p>
            <div className="d-flex gap-3 align-items-center">
              <span
                className="border d-inline-flex justify-content-center align-items-center border-border-info-subtle rounded-2 fs-3 lh-1"
                style={{ width: 36, height: 36 }}
                role="button"
                onClick={handleRemove}
              >-</span>
              <p className="mb-0 fs-5">{quantity}</p>
              <span
                className="border d-inline-flex justify-content-center align-items-center border-border-info-subtle rounded-2 fs-3 lh-1"
                style={{ width: 36, height: 36 }}
                role="button"
                onClick={handleAdd}
              >+</span>
            </div>
          </div>
          <button style={{ backgroundColor: "#EDEEF1" }} className="rounded-pill border-0 text-main-black py-1 px-3 smaller-text">
            {area || "Hectre"}
          </button>
        </section>
      </Card.Body>
    </Card>
  )
}

const EditPropertyModal = ({ show, handleClose, allProperties, setEditedProperties }) => {
  const dispatch = useDispatch();
  
  const handleUpdateProperty = (name, area, actionType) => {
    const selectedProperty = allProperties.find((property) => property.name === name && property.area === area);
    setEditedProperties((prevArray) =>
      prevArray.map((property) => {
        if (property.name === name && property.area === area) {
          if (actionType === "add") {
            return { ...property, quantity: selectedProperty.quantity+1 }
          } else if (actionType === "remove") {
            if (selectedProperty.quantity === 0) return property;
            return { ...property, quantity: selectedProperty.quantity-1 }
          }
        }
        return property
      }
      )
    );
  };

  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Body className="rounded-3" style={{ backgroundColor: "#F8F8F8", minWidth: "50%" }}>
            <h4 className="mb-3 fs-3">Add or Remove Properties</h4>
            <Row className="d-flex flex-column g-2">
              {
                allProperties.map(({ area, location, name, picture, quantity }) => (
                  <Col xs={12} key={area+name}>
                    <EditBox area={area} location={location} name={name} picture={picture} quantity={quantity} handleAdd={() => handleUpdateProperty(name, area, "add")} handleRemove={() => handleUpdateProperty(name, area, "remove")} />
                  </Col>
                ))
              }
            </Row>
            <div className="d-flex gap-2 mt-2">
              <button className="semiwhite-bg px-4 py-1 rounded-2 flex-fill" style={{ border: "2px solid #7d0007" }} onClick={handleClose}>
                Cancel
              </button>
              <button className="itechRed-bg px-4 py-1 rounded-2 flex-fill" onClick={() => {
                dispatch(updateProperties(allProperties));
                handleClose();
              }}
              >
                Continue
              </button>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default EditPropertyModal