import { Bounce, toast } from 'react-toastify';

// const { NODE_ENV: env, REACT_APP_API_URL_DEV: devUrl, REACT_APP_API_URL_PROD: prodUrl } = process.env;

const REGEXES = {
  password: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d@$!%*?&_.-]{8,}$/,
};

const getInitialDob = () => {
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  return eighteenYearsAgo.toISOString().split('T')[0];
};

// const baseUrl = env === 'development' ? devUrl : prodUrl;
const baseUrl = 'https://itech-backend.onrender.com/v1';
// const baseUrl = 'http://localhost:9090/v1';

const setHeadersConfig = (token) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  headers.Authorization = token ? `Bearer ${token}` : undefined;

  return { headers };
};

const Toast = (displayText, type = 'info', options = {}) => {
  const defaultOptions = {
    position: 'top-right',
    autoClose: type === 'error' ? 5000 : 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: type === 'success' ? Bounce : undefined,
  };

  const mergedOptions = { ...defaultOptions, ...options };

  type === 'error' ? toast.error(displayText, mergedOptions) : type === "success" ? toast.success(displayText, mergedOptions) : toast(displayText, mergedOptions);
};

const handleOnPageNavigation = (e, sectionId) => {
  e.preventDefault();
  const targetElement = document.getElementById(sectionId);

  if (targetElement) {
    window.scrollTo({
      top: targetElement.offsetTop - 70,
      behavior: 'smooth',
    });
  }
};

const getSimplifiedError = (error) => {
  console.log({ error });

  const displayError = (message) => {
    Toast(message, 'error');
    return message;
  };

  if (!error.response) {
    return displayError('Something went wrong, check your internet and please try again');
  }

  const errorObject = error.response.data;
  const status = error.response.status;

  switch (status) {
    case 500:
      return displayError('Sorry, an unexpected error occurred.');
    case 400:
      if (errorObject?.message === 'Email already taken') {
        return displayError('Phone or Email already taken');
      } else if (errorObject?.message) {
        return displayError(errorObject?.message);
      } else {
        return displayError('Oops, something went wrong!');
      }
    case 404:
      return displayError(errorObject?.message ?? 'Resource Not Found!');
    case 401:
      return displayError(errorObject?.message);
    case 405:
      return displayError(errorObject?.message ?? 'Method Not Allowed');
    default:
      return displayError('Something went wrong');
  }
};

const navigateToDashboard = (role) => {
  switch (role) {
    case 'client':
      window.location.replace('/client-dashboard');
      break;
    case 'agent':
      window.location.replace('/agent-dashboard');
      break;
    case 'admin':
      window.location.replace('/admin-dashboard');
      break;
    default:
      console.error('Unknown role: ', role);
      window.location.replace('/login');
  }
};

const sendSupportEmail = () => {
  const adminEmail = 'info@itechproperties.ng';
  const ccEmail = 'bode@itechproperties.ng';
  const mailTitle = 'Support Request';
  const mailBody = 'Hi, I need support regarding...';
  window.location.href = `mailto:${adminEmail}?cc=${ccEmail}&subject=${encodeURIComponent(
    mailTitle
  )}&body=${encodeURIComponent(mailBody)}`;
};

const truncateText = (text, limit=15) => {
  return text.substr(0, limit).concat('...');
}

function filterUsersByRole(role, allUsers) {
  if (role === "All Users") return allUsers;
  const filtered = allUsers.filter((user) => user.role === role.replace('s', '').toLowerCase());
  return filtered;
}

export function formatNumberToCurrency(number) {
  let numStr = number.toString();
  let parts = numStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

const cloudinaryURL = 'https://api.cloudinary.com/v1_1/devwillyy';

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const convertImageUrlToBase64 = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return await convertToBase64(blob);
};

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const utils = {
  getInitialDob,
  baseUrl,
  setHeadersConfig,
  Toast,
  handleOnPageNavigation,
  getSimplifiedError,
  navigateToDashboard,
  sendSupportEmail,
  truncateText,
  filterUsersByRole,
  formatNumberToCurrency,
  cloudinaryURL,
  convertToBase64,
  convertImageUrlToBase64,
  capitalizeFirstLetter,
};

export { REGEXES };
export default utils;
