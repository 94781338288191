import { useEffect } from 'react';
import { Col, Container, Figure, Row } from 'react-bootstrap';
import 'aos/dist/aos.css';
import AOS from 'aos';
import IleriSectionHeader from './IleriSectionHeader';
import { amenitiesRowOneData, amenitiesRowTwoData } from '../../data/ileri-page-data';

const IleriEstateAmenities = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="poppins-font pt-5 my-4" data-aos="fade-up">
      <Container>
        <IleriSectionHeader
          btnText="Estate Amenities"
          h3Text="Discover Our Amenities, Elevating Your Living Experience."
        />
        <Row className="px-4 pt-4 mb-xl-5 mb-lg-5 mb-md-4 mb-4 gap-xl-0 gap-lg-0 gap-md-0 gap-4">
          {amenitiesRowOneData.map((amenity) => (
            <Col xl={4} lg={4} md={4} sm={12} key={amenity.id}>
              <Figure className="d-flex justify-content-center">{amenity.svgIcon}</Figure>
              <h5 className="fw-normal fs-5 text-center">{amenity.itemText} </h5>
            </Col>
          ))}
        </Row>
        <Row className="px-4 gap-xl-0 gap-lg-0 gap-md-0 gap-4">
          {amenitiesRowTwoData.map((amenity) => (
            <Col xl={4} lg={4} md={4} sm={12} key={amenity.id}>
              <Figure className="d-flex justify-content-center">{amenity.svgIcon}</Figure>
              <h5 className="fw-normal fs-5 text-center">{amenity.itemText} </h5>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default IleriEstateAmenities;
