import { Card } from 'react-bootstrap';
import ileri from '../../assets/ileri-assets/ileri-residentials-logo.png';
import utils, { formatNumberToCurrency } from '../../utils';

const PropertyBox = ({ name, location, logo, area, price, changeType = 'increase', percentChange, quantity }) => {
  if (quantity < 1) return;

  return (
    <Card style={{ minWidth: 'max-content' }}>
      <Card.Body className="d-flex gap-3 align-items-end">
        <img
          src={logo || ileri}
          alt="Logo"
          width={150}
          height={150}
          className="border border-secondary-subtle rounded-2"
        />
        <div>
          <Card.Title className="fs-4 text-main-black mb-1">
            {utils.truncateText(name || 'Horizon Mega City', 13)}
          </Card.Title>
          <Card.Text className="moresmaller-text mb-0" style={{ color: '#656565' }}>
            {location || 'Moniya, Ibadan'}
          </Card.Text>
          <button
            style={{ backgroundColor: '#EDEEF1' }}
            className="rounded-pill border-0 text-main-black py-1 px-3 my-2 smaller-text"
          >
            {area || 'Hectre'} ({quantity})
          </button>
          <p className="moresmaller-text mb-0" style={{ color: '#656565' }}>
            Current Price
          </p>
          <h4 className="d-flex  align-items-end gap-4 text-main-black mb-0 fs-5" style={{ color: '#656565' }}>
            <span>{formatNumberToCurrency(price) || 'N1,750,000'}</span>
            <span
              className="xsmall-text"
              style={{ color: changeType === 'increase' ? '#55D38A' : changeType === '' ? '#FF111E' : 'inherit' }}
            >
              {percentChange || '10%'}
            </span>
          </h4>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PropertyBox;
