import { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Row, Col, Figure, Image, Button } from 'react-bootstrap';
import phoneImg from '../../assets/protect-assets/phone-mockup.png';
import { featuresData } from '../../data/protect-page-data';

const ProtectFeaturesSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="features" className="container inter-font py-5">
      <Row className="gap-5" data-aos="fade-up">
        <Col sm={12} className="d-flex flex-column gap-4">
          <Row>
            <div className="px-sm-0 px-4 d-flex justify-content-lg-center justify-content-sm-start justify-content-start">
              <Button className="rounded-pill px-4 fw-medium protect-btn-secondary">Features</Button>
            </div>
          </Row>
          <Row className="justify-content-lg-center justify-content-sm-start justify-content-start">
            <Col md={9} sm={12} className="px-lg-5 px-sm-2 px-4">
              <p className="fw-medium text-lg-center text-sm-left main-text-black line-larger">
                PROtect is a powerful Community Management System designed to enhance the living experience in
                residential and commercial communities. From visitor management to solar energy monitoring, PROtect
                offers a range of features to promote security, transparency, and sustainability.
              </p>
            </Col>
          </Row>
        </Col>

        <Col sm={12}>
          <Row className="justify-content-between overflow-hidden">
            <Col lg={4} sm={12} className="pt-3 order-lg-1 order-sm-1 order-1" data-aos="fade-right">
              <aside className="pt-lg-5 pt-sm-1 pt-0 d-flex flex-column gap-3">
                {featuresData.setOne.map(({ id = 'features', title, description }) => (
                  <div key={id} className="fs-6 ps-sm-0 ps-3">
                    <h5 className="primaries fw-semibold">{title}</h5>
                    <p className="text-body smaller-text line-medium">{description}</p>
                  </div>
                ))}
              </aside>
            </Col>
            <Col lg={4} sm={12} data-aos="fade-up" className="pt-lg-0 pt-sm-5 pt-5 order-lg-2 order-sm-3 order-3">
              <Figure className="d-flex justify-content-lg-center justify-content-sm-start justify-content-start">
                <Image src={phoneImg} height={470} alt="App Screenshot" />
              </Figure>
            </Col>
            <Col lg={4} sm={12} className="pt-3 order-lg-3 order-sm-2 order-2" data-aos="fade-left">
              <aside className="pt-lg-5 pt-sm-0 pt-0 d-flex flex-column gap-3">
                {featuresData.setTwo.map(({ id, title, description }) => (
                  <div key={id} className="fs-6 ps-sm-0 ps-3">
                    <h5 className="primaries fw-semibold">{title}</h5>
                    <p className="text-body smaller-text line-medium">{description}</p>
                  </div>
                ))}
              </aside>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default ProtectFeaturesSection;
