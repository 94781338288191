import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProfileDisplay } from './UserProfile';

const DashboardHeader = ({ title }) => {
  return (
    <Row>
      <Col md={12} className="mb-4 border-bottom py-4">
        <header>
          <div className="d-flex flex-row align-items-center justify-content-end justify-content-md-between">
            <h2 className="inter-font main-text-black fs-3 line-larger fw-bolder d-none d-md-block">{title}</h2>
            <ProfileDisplay />
          </div>
        </header>
      </Col>
    </Row>
  );
};

export default DashboardHeader;
