export const EmailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <path
        d="M21.75 6.75V17.25C21.75 18.4926 20.7426 19.5 19.5 19.5H4.5C3.25736 19.5 2.25 18.4926 2.25 17.25V6.75M21.75 6.75C21.75 5.50736 20.7426 4.5 19.5 4.5H4.5C3.25736 4.5 2.25 5.50736 2.25 6.75M21.75 6.75V6.99271C21.75 7.77405 21.3447 8.49945 20.6792 8.90894L13.1792 13.5243C12.4561 13.9694 11.5439 13.9694 10.8208 13.5243L3.32078 8.90894C2.65535 8.49945 2.25 7.77405 2.25 6.99271V6.75"
        stroke="#010101"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PasswordIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <path
        d="M16.5 10.5V6.75C16.5 4.26472 14.4853 2.25 12 2.25C9.51472 2.25 7.5 4.26472 7.5 6.75V10.5M6.75 21.75H17.25C18.4926 21.75 19.5 20.7426 19.5 19.5V12.75C19.5 11.5074 18.4926 10.5 17.25 10.5H6.75C5.50736 10.5 4.5 11.5074 4.5 12.75V19.5C4.5 20.7426 5.50736 21.75 6.75 21.75Z"
        stroke="#010101"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PdfIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0759 1.55469L22.2489 5.90469V22.4467H6.66016V22.5007H22.3022V5.95944L18.0759 1.55469Z" fill="#909090"/>
      <path d="M18.0227 1.5H6.60547V22.446H22.2475V5.90475L18.0227 1.5Z" fill="#FEFEFE"/>
      <path d="M6.49172 2.625H1.69922V7.74525H16.7742V2.625H6.49172Z" fill="#7A7B7C"/>
      <path d="M16.8546 7.65916H1.79688V2.53516H16.8546V7.65916Z" fill="#DD2025"/>
      <path d="M6.78884 3.40003H5.80859V7.00003H6.57959V5.78578L6.74984 5.79553C6.91527 5.79268 7.07915 5.76305 7.23509 5.70778C7.37182 5.66075 7.4976 5.58651 7.60484 5.48953C7.71397 5.39714 7.8 5.28055 7.85609 5.14903C7.93132 4.9304 7.95818 4.69805 7.93484 4.46803C7.93016 4.30371 7.90135 4.14097 7.84934 3.98503C7.802 3.87246 7.73174 3.77098 7.64303 3.68705C7.55432 3.60312 7.44911 3.53857 7.33409 3.49753C7.23465 3.46153 7.13191 3.4354 7.02734 3.41953C6.94816 3.40731 6.86821 3.40079 6.78809 3.40003M6.64634 5.12053H6.57959V4.01053H6.72434C6.78823 4.00592 6.85233 4.01573 6.91192 4.03922C6.9715 4.06272 7.02505 4.09931 7.06859 4.14628C7.15883 4.26704 7.20704 4.41403 7.20584 4.56478C7.20584 4.74928 7.20584 4.91653 7.03934 5.03428C6.91939 5.10025 6.78286 5.1307 6.64634 5.12053ZM9.39959 3.39028C9.31634 3.39028 9.23534 3.39628 9.17834 3.39853L8.99984 3.40303H8.41484V7.00303H9.10334C9.36646 7.01024 9.62843 6.96564 9.87434 6.87178C10.0723 6.79327 10.2476 6.66671 10.3843 6.50353C10.5174 6.33887 10.6128 6.14716 10.6641 5.94178C10.723 5.70918 10.7518 5.46996 10.7496 5.23003C10.7641 4.94665 10.7422 4.66257 10.6843 4.38478C10.6294 4.1803 10.5266 3.99185 10.3843 3.83503C10.2727 3.7084 10.1361 3.60626 9.98309 3.53503C9.85168 3.47422 9.71344 3.42939 9.57134 3.40153C9.51484 3.39219 9.45761 3.38793 9.40034 3.38878M9.26384 6.34153H9.18884V4.04353H9.19859C9.35321 4.02574 9.50966 4.05364 9.64859 4.12378C9.75034 4.20502 9.83325 4.30738 9.89159 4.42378C9.95456 4.54628 9.99086 4.68073 9.99809 4.81828C10.0048 4.98328 9.99809 5.11828 9.99809 5.23003C10.0011 5.35876 9.99287 5.4875 9.97334 5.61478C9.95023 5.74545 9.9075 5.87188 9.84659 5.98978C9.77766 6.09939 9.68453 6.19176 9.57434 6.25978C9.48181 6.31963 9.37223 6.34755 9.26234 6.33928M13.0723 3.40303H11.2498V7.00303H12.0208V5.57503H12.9958V4.90603H12.0208V4.07203H13.0708V3.40303" fill="#464648"/>
      <path d="M16.3365 15.1921C16.3365 15.1921 18.7275 14.7586 18.7275 15.5754C18.7275 16.3921 17.2462 16.0599 16.3365 15.1921ZM14.5687 15.2544C14.1889 15.3383 13.8187 15.4612 13.464 15.6211L13.764 14.9461C14.064 14.2711 14.3752 13.3509 14.3752 13.3509C14.7332 13.9534 15.1497 14.5191 15.6187 15.0399C15.2651 15.0926 14.9145 15.1647 14.5687 15.2559V15.2544ZM13.6222 10.3794C13.6222 9.66761 13.8525 9.47336 14.0317 9.47336C14.211 9.47336 14.4127 9.55961 14.4195 10.1776C14.3611 10.799 14.231 11.4116 14.0317 12.0031C13.7588 11.5065 13.6176 10.9483 13.6215 10.3816L13.6222 10.3794ZM10.1355 18.2664C9.40199 17.8276 11.6737 16.4769 12.0855 16.4334C12.0832 16.4341 10.9035 18.7254 10.1355 18.2664ZM19.4257 15.6721C19.4182 15.5971 19.3507 14.7669 17.8732 14.8021C17.2574 14.7922 16.6419 14.8356 16.0335 14.9319C15.4442 14.3382 14.9367 13.6685 14.5245 12.9406C14.7842 12.1902 14.9414 11.4081 14.9917 10.6156C14.97 9.71561 14.7547 9.19961 14.0647 9.20711C13.3747 9.21461 13.2742 9.81836 13.365 10.7169C13.4539 11.3206 13.6216 11.9102 13.8637 12.4704C13.8637 12.4704 13.545 13.4626 13.1235 14.4496C12.702 15.4366 12.414 15.9541 12.414 15.9541C11.681 16.1927 10.991 16.5472 10.3702 17.0041C9.75225 17.5794 9.501 18.0211 9.8265 18.4629C10.107 18.8439 11.0887 18.9301 11.9662 17.7804C12.4325 17.1865 12.8584 16.5621 13.2412 15.9114C13.2412 15.9114 14.5792 15.5446 14.9955 15.4441C15.4117 15.3436 15.915 15.2641 15.915 15.2641C15.915 15.2641 17.1367 16.4934 18.315 16.4499C19.4932 16.4064 19.4362 15.7456 19.4287 15.6736" fill="#DD2025"/>
      <path d="M17.9648 1.55859V5.96334H22.1896L17.9648 1.55859Z" fill="#909090"/>
      <path d="M18.0234 1.5V5.90475H22.2482L18.0234 1.5Z" fill="#FEFEFE"/>
      <path d="M6.7322 3.34339H5.75195V6.94339H6.52595V5.72989L6.69695 5.73964C6.86238 5.73679 7.02625 5.70716 7.1822 5.65189C7.31892 5.60484 7.4447 5.5306 7.55195 5.43364C7.66026 5.341 7.74551 5.22443 7.80095 5.09314C7.87617 4.87451 7.90304 4.64216 7.8797 4.41214C7.87502 4.24782 7.84621 4.08508 7.7942 3.92914C7.74686 3.81657 7.6766 3.71509 7.58789 3.63116C7.49918 3.54723 7.39397 3.48268 7.27895 3.44164C7.17905 3.40529 7.0758 3.37891 6.9707 3.36289C6.89152 3.35067 6.81157 3.34415 6.73145 3.34339M6.5897 5.06389H6.52295V3.95389H6.66845C6.73234 3.94928 6.79644 3.95909 6.85603 3.98258C6.91561 4.00608 6.96916 4.04267 7.0127 4.08964C7.10294 4.2104 7.15115 4.35739 7.14995 4.50814C7.14995 4.69264 7.14995 4.85989 6.98345 4.97764C6.8635 5.04361 6.72697 5.07331 6.59045 5.06314M9.34295 3.33364C9.2597 3.33364 9.1787 3.33964 9.1217 3.34189L8.94545 3.34639H8.36045V6.94639H9.04895C9.31207 6.9536 9.57404 6.909 9.81995 6.81514C10.0179 6.73663 10.1932 6.61007 10.33 6.44689C10.463 6.28223 10.5584 6.09052 10.6097 5.88514C10.6686 5.65254 10.6974 5.41332 10.6952 5.17339C10.7098 4.89001 10.6878 4.60593 10.63 4.32814C10.575 4.12366 10.4722 3.93521 10.33 3.77839C10.2183 3.65176 10.0817 3.54961 9.9287 3.47839C9.79729 3.41758 9.65905 3.37275 9.51695 3.34489C9.46045 3.33555 9.40322 3.33129 9.34595 3.33214M9.20945 6.28489H9.13445V3.98689H9.1442C9.29882 3.9691 9.45527 3.997 9.5942 4.06714C9.69595 4.14838 9.77886 4.25074 9.8372 4.36714C9.90017 4.48964 9.93647 4.62409 9.9437 4.76164C9.95045 4.92664 9.9437 5.06164 9.9437 5.17339C9.94676 5.30212 9.93847 5.43086 9.91895 5.55814C9.89584 5.68881 9.8531 5.81524 9.7922 5.93314C9.72327 6.04275 9.63014 6.13512 9.51995 6.20314C9.42742 6.26299 9.31784 6.29091 9.20795 6.28264M13.0157 3.34639H11.1932V6.94639H11.9642V5.51839H12.9392V4.84939H11.9642V4.01539H13.0142V3.34639" fill="white"/>
    </svg>
  )
}