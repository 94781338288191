import { useEffect } from 'react';
import { Container, Row, Col, Button, Figure, Image } from 'react-bootstrap';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { ileriButtonsData } from '../../data/ileri-page-data';
import ileriResidentialsLogo from '../../assets/ileri-assets/ileri-residentials-logo.png';

const IleriHeroSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="bg-light pt-2 poppins-font" data-aos="fade-right">
      <Container className="py-5">
        <Row className="position-relative mt-4 py-5">
          <Col xs={12} lg={8} className="mx-auto text-center">
            <Figure className="d-flex align-items-center justify-content-center mb-4">
              <Image src={ileriResidentialsLogo} />
            </Figure>
            <h1 className="display-4 fw-semibold mb-3">Welcome to</h1>
            <h1 className="display-4 fw-semibold mb-3">ILERI RESIDENTIALS</h1>
            <div className="d-flex justify-content-center">
              <p className="w-75 lead text-muted fs-5 small-text line-larger mb-5 px-4">
                Relax, Recharge and Reconnect yourself with nature at horizon mega city
              </p>
            </div>
            <div className="d-flex flex-row justify-content-center gap-3">
              {ileriButtonsData.map((button, index) => (
                <Button
                  key={index}
                  variant={button.variant}
                  className="fw-medium btn-padding-lg rounded-pill border-none btn-outline-none d-flex align-items-center justify-content-center gap-2"
                >
                  <span>{button.text}</span>
                  {button.icon && button.icon}
                </Button>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IleriHeroSection;
