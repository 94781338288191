import { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import utils from '../../utils';
import { ToastContainer } from 'react-toastify';
import { Form, Button, Row, Col } from 'react-bootstrap';
import FullLoader from '../loader-components/Loaders';
import ItechLoginImage from './ItechLoginImage';
import { selectRoleData } from '../../data/login-data';
import { VisionIcon } from '../globals/Buttons';
import { EmailIcon, PasswordIcon } from '../../assets/icons/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearState, loginUser } from '../../redux/reducers/authReducer';
// import NoAccount from './NoAccount';

const Login = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [role, setRole] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { loading, token } = useSelector((state) => state?.auth);

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const handleRoleClick = (selectedRole) => {
    setRole(selectedRole);
    setStep(2);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    const userRole = role.toLowerCase();

    dispatch(
      loginUser({
        data: {
          role: userRole,
          ...formData,
        },
      })
    ).then(async (response) => {
      if (response.type === 'loginUser/fulfilled') {
        const { message } = response?.payload;
        utils.Toast(message, 'success');
        // await new Promise((resolve) => setTimeout(resolve, 150));
        utils.navigateToDashboard(userRole);
      } else {
        utils.Toast('Login failed!', 'error');
      }
    });
  };

  const { type, icon } = VisionIcon();

  return (
    <main className="d-flex align-items-center justify-content-center fScreen poppins-font">
      {step === 1 && (
        <section className="mx-auto">
          <header className="mb-auto adjust-top">
            <ItechLoginImage />
          </header>
          {selectRoleData.map(({ role, text }) => (
            <Row className="mb-4" key={role}>
              <Col>
                <Button
                  className="d-flex itechRed-bg px-5 py-3 align-items-center w-100"
                  onClick={() => handleRoleClick(role)}
                >
                  <FaUser size={20} className="me-3" />
                  {text}
                </Button>
              </Col>
            </Row>
          ))}
        </section>
      )}

      {step === 2 && (
        <section>
          <aside className="adjust-top">
            <ItechLoginImage />
          </aside>
          <h2 className="mx-auto px-lg-5 display-8 fw-semibold mb-5">Login as {role}</h2>
          {loading && <FullLoader loaderText="Logging in" isColored />}
          <ToastContainer />
          <Row className="form-width-md">
            <Col md={6} className="w-100 d-flex flex-column gap-3"></Col>
          </Row>
          <Form
            className="w-100 px-4 px-md-0"
            style={{
              minWidth: '300px',
              maxWidth: '400px',
            }}
          >
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <div className="position-relative d-flex gap-2">
                <small className="position-absolute input-icons">
                  <EmailIcon />
                </small>
                <Form.Control
                  onChange={handleChange}
                  type="email"
                  name="email"
                  required
                  className="login-form-inputs"
                />
              </div>
            </Form.Group>
            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <div className="position-relative d-flex gap-2">
                <small className="position-absolute input-icons">
                  <PasswordIcon />
                </small>
                <Form.Control
                  onChange={handleChange}
                  type={type}
                  name="password"
                  required
                  className="login-form-inputs"
                />
                {icon}
              </div>
            </Form.Group>
            <Button
              type="submit"
              className="mt-4 w-100 btn-success itechRed-bg py-2"
              onClick={handleLogin}
              disabled={loading || token}
            >
              Login
            </Button>
          </Form>
        </section>
      )}
    </main>
  );
};

export default Login;
