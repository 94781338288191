import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { locationSvg } from '../../data/agent-registration-page-data';
import iTechLogo from '../../assets/images/iTechLogo.svg';
import utils from '../../utils';
import '../../styles/AgentRegistrationStepOne.css';

const AgentRegistrationStepOne = ({ onNextStepClick, formDataStepOne }) => {
  const [firstName, setFirstName] = useState(formDataStepOne.firstName || '');
  const [lastName, setLastName] = useState(formDataStepOne.lastName || '');
  const [phoneNumber, setPhoneNumber] = useState(formDataStepOne.phoneNumber || '');
  const [email, setEmail] = useState(formDataStepOne.email || '');
  const [dateOfBirth, setDateOfBirth] = useState(formDataStepOne.dateOfBirth || '');
  const [gender, setGender] = useState(formDataStepOne.gender || 'male');
  const [address, setAddress] = useState(formDataStepOne.address || '');
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [dobError, setDobError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const allFieldsFilled =
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      phoneNumber.length === 11 &&
      validateEmail(email) &&
      dateOfBirth !== '';
    setIsNextButtonDisabled(!allFieldsFilled);
  }, [firstName, lastName, phoneNumber, email, dateOfBirth]);

  useEffect(() => {
    dateOfBirth && dateOfBirth.length > 1 ? setDateOfBirth(dateOfBirth) : setDateOfBirth(utils.getInitialDob());
  }, [dateOfBirth]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(email);
    setEmailError(isValid ? '' : 'Invalid email address');
    return isValid;
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    try {
      if (selectedDate > minDate) {
        setDateOfBirth(minDate.toISOString().split('T')[0]);
        setDobError('Agent must be at least 18 years old');
      } else {
        setDateOfBirth(selectedDate.toISOString().split('T')[0]);
        setDobError('');
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;
    const numbersOnly = inputPhoneNumber.replace(/\D/g, '');
    if (numbersOnly.length > 11) {
      setPhoneNumber(numbersOnly.slice(0, 11));
    } else if (numbersOnly.length === 11) {
      setPhoneNumber(numbersOnly);
      setPhoneNumberError('');
    } else {
      setPhoneNumber(numbersOnly);
      setPhoneNumberError('Phone number must be 11 digits');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNextStepClick({ firstName, lastName, phoneNumber, email, dateOfBirth, gender, address });
  };

  return (
    <section className="agent-registration-container">
      <img
        src={iTechLogo}
        alt="ITech logo"
        onClick={() => navigate('/')}
        className="agent-registration-logo"
        role="button"
      />
      <div className="line"></div>
      <div className="agent-registration-content">
        <div className="agent-registration-header">
          <h2 className="agent-registration-title">Personal Information</h2>
          <div className="progress p-2">1 of 3</div>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="row d-flex flex-lg-row flex-sm-column flex-column gap-lg-1 gap-sm-2 gap-2">
            <div className="input-group mb-4">
              <label className="agent-registration-label">
                First Name <span className="required-field">*</span>
              </label>
              <input
                className="agent-registration-input"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter first name"
                required
              />
            </div>
            <div className="input-group mb-4">
              <label className="agent-registration-label">
                Last Name <span className="required-field">*</span>
              </label>
              <input
                className="agent-registration-input"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter last name"
                required
              />
            </div>
          </div>
          <div
            className={`row d-flex flex-lg-row flex-sm-column flex-column gap-lg-1 gap-sm-2 gap-2 ${
              emailError && 'pb-3'
            }`}
          >
            <div className="input-group mb-4">
              <label className="agent-registration-label">
                Phone Number <span className="required-field">*</span>
              </label>
              <div className="w-100 position-relative">
                <input
                  className={`agent-registration-input ${phoneNumberError && 'error'}`}
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder="Enter phone number"
                  required
                />
                {phoneNumberError && <p className="position-absolute error-message">{phoneNumberError}</p>}
              </div>
            </div>
            <div className="input-group mb-4">
              <label className="agent-registration-label">
                Email <span className="required-field">*</span>
              </label>
              <div className="w-100 position-relative">
                <input
                  className={`agent-registration-input ${emailError && 'error'}`}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => validateEmail(email)}
                  placeholder="Enter email address"
                  required
                />
                {emailError && <p className="position-absolute error-message">{emailError}</p>}
              </div>
            </div>
          </div>
          <div
            className={`row d-flex flex-lg-row flex-sm-column flex-column gap-lg-1 gap-sm-2 gap-2 ${
              dobError && 'pb-3'
            }`}
          >
            <div className="input-group mb-4">
              <label className="agent-registration-label">
                Date of Birth <span className="required-field">*</span>
              </label>
              <div className="w-100 position-relative">
                <input
                  className="agent-registration-input"
                  type="date"
                  value={dateOfBirth}
                  onChange={handleDateChange}
                  placeholder="Select date of birth"
                  max={new Date().toISOString().split('T')[0]}
                  required
                />
                {dobError && <p className="position-absolute error-message">{dobError}</p>}
              </div>
            </div>
            <div className="input-group mb-4">
              <label className="agent-registration-label">
                Gender <span className="required-field">*</span>
              </label>
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="agent-registration-input"
                required
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div className="row d-flex flex-lg-row flex-sm-column flex-column gap-lg-1 gap-sm-2 gap-2">
            <div className="input-group mb-4">
              <label className="agent-registration-label">Current Address</label>
              <div className="agent-registration-text-box-wrapper">
                <textarea
                  rows={2}
                  className="agent-registration-text-box"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Enter current address"
                ></textarea>
                <span className="position-absolute location-svg">{locationSvg}</span>
              </div>
            </div>
          </div>
          <div className="agent-registration-button-section">
            <button type="submit" className="agent-registration-next-button" disabled={isNextButtonDisabled}>
              Next
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AgentRegistrationStepOne;
