import React from 'react';
import "../styles/ResetPasswordResponse.css";
import {useNavigate} from "react-router-dom";
import mail from "../assets/images/Mail.svg";
import success from "../assets/images/Success.svg";

const ResetPasswordResponse = () => {
    const navigate = useNavigate();

    const handleDoneClick = () => {
        navigate('/login');
    };

    return (
        <div className="container">
            <div className="page-content">
                <div className="col-md-6">
                    <div className="reset-password-response-icons">
                        <img src={mail} alt="Email" className="reset-password-response-email-svg"/>
                        <img src={success} alt="Email" className="reset-password-response-success-svg"/>
                    </div>
                    <p className="forgot-password-response">Password successfully changed</p>
                    <button type="submit" className="reset-password-response-button" onClick={handleDoneClick}>
                        Proceed to Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordResponse;
