import axios from 'axios';
import utils from './index';

const baseURL = utils.baseUrl;

export const endpoints = {
  registerAgent: 'auth/register-agent',
  login: 'auth/login',
  profile: 'user/profile',
  allProfiles: 'admin/users'
};

export const APIService = axios.create({
  baseURL,
});

export const thirdPartyAPIService = (apiURL) =>
  axios.create({
    baseURL: apiURL,
  });

export const altAPIService = (method, address, headers, data = undefined) => {
  return axios({
    method,
    url: `${baseURL}/${address}`,
    headers,
    data,
  });
};
