import React from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { SlTrash } from "react-icons/sl";
import { IoPersonOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const UserBox = ({ userInfo }) => {
  const { _id, firstName, lastName, profilePictureUrl, role } = userInfo;
  const navigate = useNavigate();
  const name = `${firstName} ${lastName}`;

  return (
    <Col xxl={3} xl={4} sm={6} xs={12} className='px-2'>
      <Card className='h-100 border-secondary-subtle'>
        <Card.Body className='d-flex flex-column justify-content-between' style={{ padding: "1rem 0.7rem" }}>
          <div className='d-flex justify-content-between align-items-start w-100 mb-3'>
            <div className='d-flex gap-2'>
              <img src={profilePictureUrl} width={48} height={48} className="rounded-circle" alt="" />
              <h4 className="d-inline-flex flex-column mb-0 gap-1">
                <span className='text-body fw-semibold smaller-' style={{ fontSize: "18px" }}>{name}</span>
                <span className={`d-block moresmaller-text px-2 rounded-pill w-fit text-capitalize ${role === "agent" ? "color-agent" : "color-client"}`}>
                  {role}
                </span>
              </h4>
            </div>
            <SlTrash size={16} role="button" />
          </div>
          <div className='d-flex w-100'>
            <Button onClick={() => navigate(`/admin-dashboard/user/${_id}`, {state: { userInfo } })} size='sm' className='ms-auto border-secondary bg-white text-secondary fw-medium d-flex align-items-center gap-2'>
              <IoPersonOutline size={16} className='' color='#3D3D3D' />
              <span>Visit Profile</span>
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default UserBox