import ProtectBillboardSection from '../components/protect-page-components/ProtectBillboardSection';
import ProtectFaqsSection from '../components/protect-page-components/ProtectFaqsSection';
import ProtectFeaturesSection from '../components/protect-page-components/ProtectFeaturesSection';
import ProtectHeroSection from '../components/protect-page-components/ProtectHeroSection';
import ProtectNavbar from '../components/protect-page-components/ProtectNavbar';
import ProtectFooter from '../components/protect-page-components/ProtectFooter';
import useScrollToTop from '../custom-hooks/useScrollToTop';

const ProtectLandingPage = () => {
  useScrollToTop();

  return (
    <main className="border-top">
      <ProtectNavbar />
      <ProtectHeroSection />
      <ProtectFeaturesSection />
      <ProtectFaqsSection />
      <ProtectBillboardSection />
      <ProtectFooter />
    </main>
  );
};

export default ProtectLandingPage;
