import { useState } from 'react';
import { Form } from 'react-bootstrap';
import '../styles/EnterNewPassword.css';
import backButton from '../assets/images/BackButton.svg';
import { useNavigate } from 'react-router-dom';
import mail from '../assets/images/Mail.svg';

const EnterNewPassword = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/login');
  };

  const [newPassword, setNewPassword] = useState('');
  const [validated, setValidated] = useState(false);

  const handleNewPassword = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      console.log('New Password:', newPassword);
      // Add logic to handle password reset
    }
    setValidated(true);
  };

  return (
    <div className="container">
      <div className="page-content">
        <div className="col-md-6">
          <img src={mail} alt="Email" className="email-svg" />
          <Form noValidate validated={validated} onSubmit={handleNewPassword}>
            <div className="form-group-container">
              <Form.Group controlId="formBasicEmail" className="custom-input">
                <Form.Label className="new-password-label-top">Enter New Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Enter New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a password you can remember.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <button type="submit" className="custom-button new-password-button">
              Continue
            </button>

            <button type="button" onClick={handleBackClick} className="custom-button back-to-login-button">
              <img src={backButton} alt="Back" className="back-button-svg" />
              Back to log in
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EnterNewPassword;
