import { Modal, Spinner } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';

const ClipLoaders = ({ isColored }) => {
  return <ClipLoader color={isColored ? '#7D0007' : '#f1f1f1'} size={25} width={40} height={25} />;
};

const Spinners = () => {
  return (
    <div className="d-flex align-items-center justify-content-center py-8">
      <ClipLoader color={'#7D0007'} size={20} aria-label="Loading Spinner" data-testid="loader" />
    </div>
  );
};

const Loader = ({ loaderText, isColored }) => (
  <div className={`d-flex align-items-center justify-content-center text-xl gap-3`}>
    <span className={isColored ? 'text-success' : 'text-white'}>{loaderText}</span>
    <Spinners />
  </div>
);

const FullLoader = ({ loaderText, isColored }) => {
  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      centered
      className="fScreen d-flex align-items-center justify-content-center poppins-font"
    >
      <Modal.Body className="login-modal text-center">
        <Spinner animation="border" variant={isColored ? 'danger' : 'white'} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div className="mt-3 text-white">{loaderText}</div>
      </Modal.Body>
    </Modal>
  );
};

export { ClipLoaders, Loader };

export default FullLoader;
