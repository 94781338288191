import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { endpoints, altAPIService } from '../../utils/apiService';
import utils from '../../utils';

const initialState = {
  loading: false,
  agentRegistrationSuccess: false,
  sentOtpSuccess: false,
  resendOtpSuccess: false,
  verifyOtp: false,
  resetPassword: false,
  verifiedStatus: false,
  token: '',
};

// Async thunk for registering agent
export const registerAgent = createAsyncThunk('registerAgent', async (payload, { rejectWithValue }) => {
  try {
    const req = {
      method: 'post',
      address: endpoints.registerAgent,
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload?.data,
    };

    const { data } = await altAPIService(req.method, req.address, req.headers, req.data);
    return data;
  } catch (error) {
    console.log({ error });
    return rejectWithValue(utils.getSimplifiedError(error));
  }
});

// Async thunk for logging in user
export const loginUser = createAsyncThunk('loginUser', async (payload, { rejectWithValue }) => {
  try {
    const req = {
      method: 'post',
      address: endpoints.login,
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload?.data,
    };

    const { data } = await altAPIService(req.method, req.address, req.headers, req.data);
    return data;
  } catch (error) {
    console.log({ error });
    return rejectWithValue(utils.getSimplifiedError(error));
  }
});

// Async thunk for logging out user
export const logoutUser = createAsyncThunk('logoutUser', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await altAPIService.post(endpoints.logout, payload);
    return data;
  } catch (error) {
    return rejectWithValue(utils.getSimplifiedError(error));
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateUserToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAgent.pending, (state) => {
        state.loading = true;
        state.agentRegistrationSuccess = false;
      })
      .addCase(registerAgent.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.agentRegistrationSuccess = true;
        state.token = payload.token;
      })
      .addCase(registerAgent.rejected, (state) => {
        state.loading = false;
        state.agentRegistrationSuccess = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.token = payload.token;
      })
      .addCase(loginUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.loading = false;
        state.token = '';
      })
      .addCase(logoutUser.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const authSelector = (state) => state.auth;
export const { clearState, updateUserToken } = authSlice.actions;

const authReducer = authSlice.reducer;
export default authReducer;
