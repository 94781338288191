import { Fragment, useEffect, useState } from 'react';
import { Offcanvas, Nav, Button } from 'react-bootstrap';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSidebarData } from '../../data/dashboard-data';
import { clearState } from '../../redux/reducers/authReducer';
import iTechLogo from '../../assets/images/iTechLogo.svg';
import utils from '../../utils';

const MobileDashboardDefaultSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);

  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebar, setSidebar] = useState('');

  const toggleSidebar = () => setShowSidebar(!showSidebar);

  useEffect(() => {
    setSidebar(getSidebarData(userData?.user?.role));
  }, [userData]);

  // console.log({ userData });

  const handleLogout = (label) => {
    if (label === 'logout') {
      dispatch(clearState());
      navigate('/');
    }

    if (label === 'support') {
      utils.sendSupportEmail();
    }
  };

  return (
    <Fragment>
      <Button
        variant="light"
        className="position-fixed top-0 start-0 mx-3 zIndex-lg custom-block-margin-sm d-md-none"
        onClick={toggleSidebar}
      >
        {showSidebar ? <AiOutlineClose size={32} /> : <AiOutlineMenu size={30} />}
      </Button>

      <Offcanvas show={showSidebar} onHide={toggleSidebar} className="d-md-none" placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src={iTechLogo} alt="Company Logo" width="120" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            {sidebar?.top?.map((item) => (
              <Nav.Link
                key={item.id}
                onClick={() => {
                  toggleSidebar();
                  item.path ? navigate(item.path) : handleLogout(item.label);
                }}
                className="py-3 d-flex align-items-center"
              >
                {item.icon}
                <span className="ms-3">{item.text}</span>
              </Nav.Link>
            ))}
            <div className="mt-auto">
              {sidebar?.bottom?.map((item) => (
                <Nav.Link
                  key={item.id}
                  onClick={() => {
                    toggleSidebar();
                    item.path ? navigate(item.path) : handleLogout(item.label);
                  }}
                  className="py-3 d-flex align-items-center"
                >
                  {item.icon}
                  <span className="ms-3">{item.text}</span>
                </Nav.Link>
              ))}
            </div>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default MobileDashboardDefaultSidebar;
