import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { IoIosClose } from 'react-icons/io';
import { ClipLoaders } from '../loader-components/Loaders';
import utils from '../../utils';
import { loginUser } from '../../redux/reducers/authReducer';
import { useDispatch, useSelector } from 'react-redux';

const HttpModal = ({ onClose, action, isClosable, mainText, btnText, svgIcon, successPayload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, token } = useSelector((state) => state.auth);

  const handleLoginToDashboard = async () => {
    const { status, message, ...payload } = successPayload;

    if (action.isAdminLogin && successPayload?.isNeverLoggedIn) {
      dispatch(loginUser({ data: payload }));
    }
  };

  useEffect(() => {
    if (token) {
      utils.Toast('Login successful!', 'success');
      utils.navigateToDashboard('agent');
    }
  }, [token, navigate]);

  return (
    <Modal.Dialog className="bg-white poppins-font border rounded-2 mx-auto p-4 mt-5 w-75 h-96 lg:h-350 lg:w-520">
      <Modal.Body className="bg-white pb-4 rounded d-flex flex-column gap-3">
        <div className="position-absolute top-0 end-0 cursor-pointer" role="button" onClick={onClose}>
          {isClosable && <IoIosClose size={40} className="text-secondary" />}
        </div>
        <div className={`mb-3 pt-4 d-flex justify-content-center align-items-start ${isClosable ? 'mt-5' : 'mt-0'}`}>
          {svgIcon}
        </div>
        <p className="text-18 fs-5 text-center text-secondary pb-4">{mainText}</p>
        <div className="d-flex justify-content-center">
          <Button
            variant="success"
            className={`px-8 py-3 itechRed-bg ${loading ? 'd-flex justify-content-center gap-3' : 'd-inline-block'}`}
            onClick={() => handleLoginToDashboard()}
            disabled={loading}
          >
            {loading && <ClipLoaders isColored />}
            <span className="submit-text">{btnText}</span>
          </Button>
        </div>
      </Modal.Body>
    </Modal.Dialog>
  );
};

export default HttpModal;
