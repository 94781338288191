import { useState } from 'react';
import { Row, Col, Figure, Image } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import minusCircle from '../../assets/protect-assets/minus-circle.svg';
import plusCircle from '../../assets/protect-assets/plus-circle.svg';
import { accordionData } from '../../data/protect-page-data';

function CustomToggle({ children, eventKey }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useAccordionButton(eventKey, () => {
    setIsOpen((isOpen) => !isOpen);
  });

  return (
    <Figure className="d-flex gap-4" role="button" onClick={handleToggle}>
      <Image src={isOpen ? minusCircle : plusCircle} />
      {children}
    </Figure>
  );
}

function AccordionContainer() {
  return (
    <Accordion className="d-flex flex-column gap-4 px-md-0 px-sm-2 px-2" alwaysOpen>
      {accordionData.map((item, index) => (
        <Row key={index} className="justify-content-center custom-border rounded-2 py-3">
          <Col lg={7} md={10} sm={12} xs={12}>
            <Row>
              <Col className="d-flex gap-3 align-items-center">
                <CustomToggle eventKey={index.toString()} index={index}>
                  <span className="main-text-black fw-semibold">{item.question}</span>
                </CustomToggle>
              </Col>
            </Row>
            <Accordion.Collapse eventKey={index.toString()}>
              <p className="ps-5 text-body smaller-text line-medium">{item.answer}</p>
            </Accordion.Collapse>
          </Col>
        </Row>
      ))}
    </Accordion>
  );
}

export default AccordionContainer;
