import { useState } from 'react';
import { FaEdit, FaSignOutAlt, FaCog } from 'react-icons/fa';
import { FaMoneyBills } from 'react-icons/fa6';
import { IoIosArrowDown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { Card, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { clearState } from '../../redux/reducers/authReducer';

const cardItems = [
  {
    icon: <FaCog className="text-success" />,
    label: 'Settings',
    path: '/agent-dashboard/settings',
  },
  {
    icon: <FaMoneyBills className="text-success" />,
    label: 'Billings',
    path: '/agent-dashboard/support',
  },
];

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user.userData) || {};

  const [showCard, setShowCard] = useState(false);

  const handleToggleCard = () => {
    setShowCard(!showCard);
  };

  const handleItemClick = (route) => {
    handleToggleCard();
    navigate(route);
  };

  const handleLogout = () => {
    dispatch(clearState());
    navigate('/');
  };

  return (
    <div role="button" className="shadow-md rounded-2 p-2 d-flex align-items-center position-relative moresmaller-text">
      <div className="d-flex align-items-center" onClick={handleToggleCard} role="button">
        <img
          src={user?.profilePictureUrl}
          alt="User"
          className="rounded-circle"
          style={{
            height: '40px',
            width: '40px',
          }}
        />
        <IoIosArrowDown size={20} className="text-gray-500 ml-2" />
      </div>

      {showCard && (
        <Card
          className="position-absolute top-100 end-0 mt-2 bg-dark text-white"
          style={{
            width: '18rem',
            zIndex: 999,
          }}
        >
          <Card.Body className="text-center border-bottom border-secondary pb-4">
            <label
              htmlFor="image-upload"
              className="d-flex flex-column align-items-center text-white-50 cursor-pointer"
            >
              <div className="position-relative">
                <img
                  src={user?.profilePictureUrl}
                  alt="User"
                  className="rounded-circle"
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                />
                <FaEdit
                  size={20}
                  className="text-secondary position-absolute"
                  style={{
                    bottom: '8px',
                    right: '-12px',
                  }}
                />
              </div>
              <span className="pt-2 fw-light">Change Profile Image</span>
            </label>
            <input type="file" id="image-upload" className="d-none" />
          </Card.Body>
          <ListGroup variant="flush" className="fw-light">
            <ListGroup.Item className="bg-dark border-secondary text-white-50">
              Name: {`${user?.firstName} ${user?.lastName}`}
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-secondary text-white-50">Email: {user?.email}</ListGroup.Item>
          </ListGroup>
          <ListGroup variant="flush">
            {cardItems.map((item, index) => (
              <ListGroup.Item
                role="button"
                key={index}
                className="fw-light bg-dark border-secondary text-white-50 d-flex align-items-center gap-2 cursor-pointer"
                onClick={() => handleItemClick(item.path)}
              >
                {item.icon} {item.label}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Card.Footer className="bg-dark text-center">
            <div
              className="fw-light text-white-50 d-flex align-items-center justify-content-center gap-2 cursor-pointer"
              onClick={handleLogout}
              role="button"
            >
              <FaSignOutAlt className="text-success" />
              <span>Logout</span>
            </div>
          </Card.Footer>
        </Card>
      )}
    </div>
  );
};

export const ProfileDisplay = () => {
  const { user } = useSelector((state) => state.user.userData) || {};

  return (
    <aside className="d-flex align-items-center gap-md-3">
      <div className="text-center mb-2 mb-md-0">
        <img src={user?.profilePictureUrl} alt="Profile" width={80} height={80} className="rounded-circle" />
      </div>
      <div className="d-none d-md-block">
        <div className="d-flex flex-column align-items-start justify-content-start">
          <span className="h5 font-weight-bold">{`${user?.firstName} ${user?.lastName}`}</span>
          <span className="text-muted moresmaller-text">{user?.email}</span>
        </div>
      </div>
    </aside>
  );
};

export default UserProfile;
