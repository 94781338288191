import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import DashboardDefaultSidebar from './DashboardDefaultSidebar';
import MobileDashboardDefaultSidebar from './MobileDashboardDefaultSidebar';
import { getUserProfile } from '../../redux/reducers/userReducer';

function DashboardRootLayout({ children }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [auth.token, dispatch]);

  return (
    <Container fluid className="poppins-font fScreen d-flex flex-column ps-0">
      <MobileDashboardDefaultSidebar />

      <Row className="flex-grow-1 mx-0">
        <Col lg={3} md={3} className="px-0">
          <DashboardDefaultSidebar />
        </Col>
        <Col md={9} className="bg-editorMain">
          {children}
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardRootLayout;
