import { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Col, Container, Row, Figure, Image, Button } from 'react-bootstrap';
import { downloadButtons } from '../../data/protect-page-data';
import phoneBillboardImg from '../../assets/protect-assets/phone-billboard.svg';

const ProtectBillboardSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="inter-font my-5 py-5 text-white" data-aos="fade-up">
      <Container>
        <Row className="justify-content-center px-md-0 px-sm-4 px-4">
          <Col md={11} sm={12} className="primary-bg px-lg-5 pt-lg-3 pb-lg-5 px-sm-4 px-4 py-sm-4 py-4 overflow-hidden">
            <Row className="gap-lg-0 gap-sm-5 gap-5">
              <Col lg={5} md={12} sm={12} xs={12} className="d-flex flex-column gap-3 pt-lg-5">
                <div>
                  <h3 className="fw-semibold fs-2">Get the PROtect App today</h3>
                </div>
                <div>
                  <p className="fw-normal smaller-text text-semiwhite line-medium">
                    Experience seamless community management, enhanced engagement, and a connected tomorrow. Download
                    now for a smarter, safer, and more vibrant community living.
                  </p>
                </div>
                <div className="mt-2 d-flex flex-row justify-content-start gap-3">
                  {downloadButtons.map((button, index) => (
                    <Button
                      key={index}
                      variant={button.variant}
                      className="fw-medium protect-btn border-white btn-padding-lg btn-padding-lg-custom rounded-pill d-flex gap-2 align-items-center justify-content-center"
                    >
                      {button.icon}
                      <span className="text-nowrap">{button.text}</span>
                    </Button>
                  ))}
                </div>
              </Col>
              <Col lg={7} md={12} sm={12} xs={12} className="d-flex align-items-start">
                <Figure>
                  <Image src={phoneBillboardImg} alt="Billboard Image" />
                </Figure>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProtectBillboardSection;
