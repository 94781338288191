import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar, Button, NavDropdown } from 'react-bootstrap';
import iTechLogo from '../../assets/images/iTechLogo.svg';
import '../../styles/TopNav.css';
import ScheduleInspectionModal from '../schedule-inspection-components/ScheduleInspectionModal';
import utils from '../../utils';
import { topNavLinksData, topNavDropdownItemsData, topNavActionButtonsData } from '../../data/landing-page-data';

const TopNav = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleItemClick = (route) => {
    navigate(route);
  };

  const handleInspectionModalClick = () => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="position-relative topNav-height pt-2">
      <Navbar className="navbar" expand="lg">
        <Container className="px-4 px-md-0">
          <Navbar.Brand>
            <img src={iTechLogo} width="110" height="32" className="d-inline-block align-top" alt="ITech logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="flex-column flex-lg-row">
            <Nav className="me-auto mb-2 mb-lg-0">
              <NavDropdown title="Our Projects" id="basic-nav-dropdown">
                {topNavDropdownItemsData.map((item) => (
                  <NavDropdown.Item key={item.route} onClick={() => handleItemClick(item.route)} className="py-2">
                    {item.label}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              {topNavLinksData.map((link) => (
                <Nav.Link
                  key={link.label}
                  onClick={(e) =>
                    link.onClick ? handleInspectionModalClick() : utils.handleOnPageNavigation(e, link.href)
                  }
                  className="text-nowrap modal-toggler"
                >
                  {link.label}
                </Nav.Link>
              ))}
            </Nav>
            <div className="d-lg-none mb-2" style={{ display: 'grid', placeItems: 'center' }}>
              {topNavActionButtonsData.map((button) => (
                <Button
                  key={button.label}
                  onClick={() => handleItemClick(button.route)}
                  className={`border border-black ${button.className} w-30 ${button.marginTop ? 'mt-2' : ''}`}
                >
                  {button.label}
                </Button>
              ))}
            </div>
            <div className="d-none d-lg-flex flex-column flex-lg-row justify-content-between gap-3">
              {topNavActionButtonsData.map((button) => (
                <Button key={button.label} onClick={() => handleItemClick(button.route)} className={button.className}>
                  {button.label}
                </Button>
              ))}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {isModalOpen && <ScheduleInspectionModal isOpen={isModalOpen} onClose={handleCloseModal} />}
    </section>
  );
};

export default TopNav;
