import { Button, ButtonGroup, Card, Col, Row, ToggleButton } from 'react-bootstrap';
import DashboardRootLayout from '../../components/dashboard-components/DashboardRootLayout';
import useResize from '../../custom-hooks/useResize';
import { useSelector } from 'react-redux';
import { forwardRef, useEffect, useState } from 'react';
import DashboardHeader from '../../components/globals/DashboardHeader';
import { TbArrowsUpDown, TbCurrencyNaira } from 'react-icons/tb';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import {
  appraisalDocuments,
  buttonGroupData,
  getClientChartData,
  getClientOverviewData,
} from '../../data/dashboard-data';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import ReactDatePicker from 'react-datepicker';
import { IoFilter } from 'react-icons/io5';
import { CiCalendar } from 'react-icons/ci';
import RepoDocumentBox from '../../components/dashboard-components/RepoDocumentBox';
import FullLoader from '../../components/loader-components/Loaders';

Chart.register(CategoryScale);

const ClientOverview = () => {
  const { width } = useResize();

  const [dateValue, setDateValue] = useState(null);
  const [timeFrame, setTimeFrame] = useState('yearly');
  const [currentChartData, setCurrentChartData] = useState(getClientChartData(timeFrame));
  const [clientOverviewData, setClientOverviewData] = useState([]);

  const { user } = useSelector((state) => state.user.userData) || {};
  const { fetchLoading } = useSelector((state) => state.user) || {};

  const [page, setPage] = useState(1);
  const n = 5;

  useEffect(() => {
    if (user) setClientOverviewData(getClientOverviewData(user));
  }, [user]);

  useEffect(() => {
    setCurrentChartData(getClientChartData(timeFrame));
  }, [timeFrame]);

  const DateButton = forwardRef(({ value, onClick, className }, ref) => (
    <Button className={className} variant="outline-secondary" onClick={onClick} ref={ref}>
      <CiCalendar size={20} />
      <span className="ps-3">{value || 'Select Dates'}</span>
    </Button>
  ));

  const handleNextPage = () => {
    if (page <= Math.floor(appraisalDocuments.length / n)) {
      setPage((prev) => (prev += 1));
    } else {
      setPage(page);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prev) => (prev -= 1));
    } else {
      setPage(page);
    }
  };

  return (
    <DashboardRootLayout>
      <section className="pb-5 moresmaller-text">
        <Row>
          <DashboardHeader title="Dashboard" />
          {fetchLoading ? (
            <FullLoader loaderText="Fetching Client Profile" isColored />
          ) : (
            <Col md={12} className="px-3 mb-3 py-2">
              <aside>
                <div>
                  <h3 className="inter-font fs-3 line-larger">{`Welcome, ${user?.firstName}`}</h3>
                  <p>Your Properties summary and activity.</p>
                </div>
                <Row className="w-100 gap-md-0 gap-3">
                  {clientOverviewData?.map(({ id, text, title, isMonetary, changeType, changePercent }) => (
                    <Col key={id} xs={12} md={3} className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body>
                          <Card.Title className="mb-1 mt-0 moresmaller-text">{text}</Card.Title>
                          <Card.Text className="mt-1 mb-0 small-text">
                            <span className="d-flex align-items-center">
                              {isMonetary && <TbCurrencyNaira size={21} />}
                              {title}
                            </span>
                            <span className="pt-1 d-flex align-items-center justify-content-end moresmaller-text">
                              {changeType === 'increment' ? (
                                <IoIosArrowRoundUp size={21} className="text-success pe-1" />
                              ) : changeType === 'decrement' ? (
                                <IoIosArrowRoundDown size={21} className="text-danger pe-1" />
                              ) : changeType === 'constant' ? (
                                <TbArrowsUpDown size={20} className="text-warning pe-1" />
                              ) : null}
                              {changePercent && `${changePercent} %`}
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Card className="my-4 pb-4">
                  <Card.Body>
                    <Card.Title className="mb-1 mt-0 ">Your Properties</Card.Title>
                    <Row className="my-3 justify-content-between row-gap-3" sm={'auto'}>
                      <Col sm={12} md={6} className="text-start">
                        <ButtonGroup>
                          {buttonGroupData.map((radio) => (
                            <ToggleButton
                              key={radio.value}
                              id={radio.value}
                              type="radio"
                              className={`${
                                timeFrame === radio.value ? 'bg-body-secondary' : 'bg-white'
                              } text-secondary border-secondary smaller-text`}
                              value={radio.value}
                              checked={timeFrame === radio.value}
                              onChange={(e) => setTimeFrame(e.currentTarget.value)}
                            >
                              {radio.name}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                      </Col>
                      <Col sm={12} md={6} className="d-flex gap-4">
                        <ReactDatePicker
                          id="dateInput"
                          className="form-control"
                          selected={dateValue}
                          onChange={(date) => setDateValue(date)}
                          dateFormat="dd/MM/yyyy"
                          customInput={<DateButton />}
                        />
                        <Button variant="outline-secondary py-0">
                          <IoFilter size={20} />
                          <span className="ps-3">Filters</span>
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Line
                    data={currentChartData}
                    options={{
                      scales: {
                        y: {
                          title: {
                            display: true,
                            text: 'Property rating (2023)',
                            padding: width < 640 ? 10 : 20,
                          },
                          min: 0,
                        },
                      },
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </Card>
                <Card className="shadow-md mb-4">
                  <Card.Header className="py-3 bg-white">
                    <Card.Title className="mb-1 mt-0 small-text">Document Repository</Card.Title>
                    <Card.Text className="smaller-text">
                      These companies have purchased in the last 12 months.
                    </Card.Text>
                  </Card.Header>
                  <div className="d-flex align-items-center w-100 py-2 px-4 fw-semibold bg-body-secondary border-secondary-subtle border-bottom border-top">
                    <Col xs={7}>Name</Col>
                    <Col xs={4}>Modified on</Col>
                    <Col xs={1} />
                  </div>
                  <div className="w-100">
                    {appraisalDocuments
                      .slice(
                        n * (page - 1),
                        page * n < appraisalDocuments.length ? page * n : appraisalDocuments.length
                      )
                      .map(({ name, date, link }) => (
                        <RepoDocumentBox name={name} link={link} modifiedOn={date} key={name} />
                      ))}
                  </div>
                </Card>

                <div className="d-flex justify-content-between align-items-center border-top border-secondary mx-2 pt-2 px-3">
                  <Button
                    className="bg-white border-secondary-subtle fw-medium text-secondary"
                    onClick={handlePrevPage}
                  >
                    Previous
                  </Button>
                  <span>
                    Page {page} of {Math.ceil(appraisalDocuments.length / n)}
                  </span>
                  <Button
                    className="bg-white border-secondary-subtle fw-medium text-secondary"
                    onClick={handleNextPage}
                  >
                    Next
                  </Button>
                </div>
              </aside>
            </Col>
          )}
        </Row>
      </section>
    </DashboardRootLayout>
  );
};

export default ClientOverview;
