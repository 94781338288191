import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoaders } from '../loader-components/Loaders';
import { passwordInvalidMsg, successSvg } from '../../data/agent-registration-page-data';
import HttpModal from '../modal-components/HttpModal';
import utils, { REGEXES } from '../../utils';
import { registerAgent } from '../../redux/reducers/authReducer';
import { VisionIcon } from '../globals/Buttons';
import { PasswordIcon } from '../../assets/icons/Icons';
import iTechLogo from '../../assets/images/iTechLogo.svg';
import '../../styles/AgentRegistrationStepThree.css';

const AgentRegistrationStepThree = ({ onPreviousStepClick, formDataStepOne, formDataStepTwo, formDataStepThree }) => {
  const [password, setPassword] = useState(formDataStepThree.password ?? '');
  const [confirmPassword, setConfirmPassword] = useState(formDataStepThree.confirmPassword ?? '');
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [passwordError, setPasswordError] = useState('');
  const [successPayload, setSuccessPayload] = useState({});
  const [isShowModal, setIsShowModal] = useState({ isError: false, isShow: false });

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state?.auth);

  useEffect(() => {
    const isValidPassword = REGEXES.password.test(password);
    const passwordsMatch = password === confirmPassword;
    setIsSubmitButtonDisabled(!isValidPassword || !passwordsMatch);
    setPasswordError(!isValidPassword ? passwordInvalidMsg : !passwordsMatch && 'Passwords do not match');
  }, [password, confirmPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      registerAgent({
        data: {
          password,
          ...formDataStepOne,
          ...formDataStepTwo,
        },
      })
    ).then((response) => {
      if (response.type === 'registerAgent/fulfilled') {
        setIsShowModal({ isError: false, isShow: true });
        setSuccessPayload(response?.payload);
        utils.Toast(response?.payload?.message, 'success');
      } else {
        utils.Toast('Agent Registration failed!', 'error');
      }
    });
  };

  const { type, icon, typeConfirm, iconConfirm } = VisionIcon();

  return (
    <Fragment>
      <section className="agent-registration-container">
        <img src={iTechLogo} alt="ITech logo" className="agent-registration-logo" />
        <aside className="line"></aside>

        <aside className="agent-registration-content">
          <div className="agent-registration-header">
            <h2 className="agent-registration-title">Create Password</h2>
            <div className="progress">3 of 3</div>
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <div className="input-group-page-three">
              <label className="agent-registration-page-three-label">
                Password <span className="required-field">*</span>
              </label>
              <div className="position-relative password-input-container">
                <small className="position-absolute input-icons agent-reg">
                  <PasswordIcon />
                </small>
                <input
                  className="agent-registration-input step-three"
                  type={type}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter a Password"
                  required
                />
                <small className="position-absolute agent-reg">{icon}</small>
              </div>
            </div>
            <div className="input-group-page-three">
              <label className="agent-registration-page-three-label">
                Confirm Password <span className="required-field">*</span>
              </label>
              <div className="position-relative password-input-container">
                <small className="position-absolute input-icons agent-reg">
                  <PasswordIcon />
                </small>
                <input
                  className="agent-registration-input step-three"
                  type={typeConfirm}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Re-enter Password"
                  required
                />
                <small className="position-absolute agent-reg">{iconConfirm}</small>
              </div>
              {passwordError && <p className="error-message">{passwordError}</p>}
            </div>
            <div className="agent-registration-buttons-section">
              <button
                onClick={() => onPreviousStepClick({ password, confirmPassword })}
                className="agent-registration-previous-button"
              >
                Previous
              </button>
              <button
                type="submit"
                className={`${
                  loading ? 'd-flex justify-content-center gap-3' : 'd-inline-block'
                } agent-step3-form-btn agent-registration-next-button`}
                disabled={isSubmitButtonDisabled || loading}
              >
                {loading && <ClipLoaders />}
                <span className="submit-text">Submit</span>
              </button>
            </div>
          </form>
        </aside>
      </section>

      {isShowModal.isShow && !isShowModal.isError && (
        <section className="position-absolute left-0 top-0 w-100 pt-2 h-100 bg-black bg-opacity-75">
          <HttpModal
            onClose={() => setIsShowModal({ isError: true, isShow: false })}
            action={{ isAdminLogin: true }}
            isClosable={false}
            mainText="Agent registration successful!"
            btnText="Proceed to Dashboard"
            svgIcon={successSvg}
            successPayload={successPayload}
          />
        </section>
      )}
    </Fragment>
  );
};

export default AgentRegistrationStepThree;
