import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const fetchCount = (amount = 1) => {
  return new Promise((resolve) =>
    setTimeout(
      () =>
        resolve({
          data: amount,
        }),
      500
    )
  );
};

// Initial state
const initialState = {
  value: 0,
  status: 'idle',
};

// Thunk for asynchronous increment
export const incrementAsync = createAsyncThunk('counter/fetchCount', async (amount) => {
  const response = await fetchCount(amount);
  return response.data;
});

// Create slice
const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      });
  },
});

// Export actions
export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// Selector to get the counter value
export const selectCount = (state) => state.counter.value;

// Thunk to increment if the current value is odd
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

// Export reducer
const counterReducer = counterSlice.reducer;
export default counterReducer;
