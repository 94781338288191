import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import '../styles/ClientSignUpPage.css';
import iTechLogo from '../assets/images/iTechLogo.svg';
import { Link } from 'react-router-dom';

const ClientSignUpPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);

  const handleSignUp = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      console.log('First Name:', firstName);
      console.log('Last Name:', lastName);
      console.log('Email:', email);
      console.log('Password:', password);
    }
    setValidated(true);
  };

  return (
    <div className="signup-page-container">
      <div className="signup-page-content">
        <div className="col-md-6">
          <img src={iTechLogo} alt="ITech logo" className="img-fluid" />
          <h2 className="mt-3 signup-title">Create your account</h2>
          <p className="welcome-message">Welcome! Please enter your details to sign up.</p>
          <Form noValidate validated={validated} onSubmit={handleSignUp}>
            <div className="form-group-container">
              <Form.Group controlId="formBasicFirstName" className="custom-input">
                <Form.Label className="label-top">First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please provide a first name.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicLastName" className="custom-input">
                <Form.Label className="label-top">Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please provide a last name.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicEmail" className="custom-input">
                <Form.Label className="label-top">Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="custom-input">
                <Form.Label className="label-top">Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please provide a password.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicConfirmPassword" className="custom-input">
                <Form.Label className="label-top">Confirm Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please confirm your password.</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="already-have-account">
              Already have an account?{' '}
              <Link to="/login" className="custom-link">
                Sign In
              </Link>
            </div>

            <button type="submit" className="custom-button sign-in-button">
              Sign Up
            </button>

            <button type="button" className="custom-button google-sign-in-button">
              Sign Up with Google
            </button>

            <div className="agreement-text">
              By clicking the Sign Up button, you agree to our <br />
              <Link to="/terms-and-conditions" className="custom-link">
                Terms and Conditions
              </Link>{' '}
              and
              <Link to="/privacy-policy" className="custom-link">
                {' '}
                Policy Privacy
              </Link>
              .
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ClientSignUpPage;
