import UserBox from "../../components/dashboard-components/UserBox";

const AllUsersList = ({ data }) => {
  return <>
    {
      data.length ? data?.map((userInfo) => (
        <UserBox userInfo={userInfo} key={userInfo?._id} />
      ))
      : <h4 className="text-danger-emphasis">No Users Found</h4>
    }
  </>;
};

export default AllUsersList;
