import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { endpoints, altAPIService, thirdPartyAPIService } from '../../utils/apiService';
import utils from '../../utils';

const initialState = {
  loading: false,
  updating: false,
  fetchLoading: false,
  updateProfileSuccess: false,
  uploadProfilePicLoading: false,
  uploadProfilePicSuccess: false,
  userData: {},
  fetchProfileSuccess: false,
  emailVerificationStatus: false,
  token: '',
};

// Async thunk for uploading profile picture to Cloudinary
export const uploadProfilePicture = createAsyncThunk(
  'uploadProfilePicture',
  async (profilePic, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', profilePic);
      formData.append('upload_preset', 'castlehub-uploads');

      const response = await thirdPartyAPIService(utils.cloudinaryURL).post('/image/upload', formData);
      const { secure_url: data } = response.data;
      return data;
    } catch (error) {
      console.log('Error uploading to Cloudinary:', error);
      return rejectWithValue(utils.getSimplifiedError(error));
    }
  }
);

// Async thunk for updating user profile
export const updateUserProfile = createAsyncThunk(
  'updateUserProfile',
  async (payload, { rejectWithValue, getState }) => {
    const { auth } = getState();

    try {
      const req = {
        method: 'put',
        address: endpoints.profile,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        data: payload?.data,
      };

      const { data } = await altAPIService(req.method, req.address, req.headers, req.data);
      return data;
    } catch (error) {
      console.log({ error });
      return rejectWithValue(utils.getSimplifiedError(error));
    }
  }
);

// Async thunk for fetching user profile
export const getUserProfile = createAsyncThunk('getUserProfile', async (_, { rejectWithValue, getState }) => {
  const { auth } = getState();

  try {
    const req = {
      method: 'get',
      address: endpoints.profile,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };

    const { data } = await altAPIService(req.method, req.address, req.headers);
    return data;
  } catch (error) {
    console.log({ error });
    return rejectWithValue(utils.getSimplifiedError(error));
  }
});

export const verifyUserEmail = createAsyncThunk('verifyUserEmail', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await altAPIService.post(`${endpoints.verifyUserEmail}?${payload}`);
    return data;
  } catch (error) {
    return rejectWithValue(utils.getSimplifiedError(error));
  }
});

// Create user slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUserState: (state) => {
      state.loading = false;
      state.updating = false;
      state.fetchLoading = false;
      state.updateProfileSuccess = false;
      state.uploadProfilePicLoading = false;
      state.uploadProfilePicSuccess = false;
      state.fetchProfileSuccess = false;
      state.emailVerificationStatus = false;
      state.userData = {};
    },
    updateUserToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.updating = true;
        state.updateProfileSuccess = false;
      })
      .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.updating = false;
        state.fetchLoading = false;
        state.updateProfileSuccess = true;
        state.userData = { ...state.userData, ...payload };
      })
      .addCase(updateUserProfile.rejected, (state) => {
        state.loading = false;
        state.updating = false;
        state.fetchLoading = false;
        state.updateProfileSuccess = false;
      })
      .addCase(uploadProfilePicture.pending, (state) => {
        state.uploadProfilePicLoading = true;
        state.uploadProfilePicSuccess = false;
      })
      .addCase(uploadProfilePicture.fulfilled, (state, { payload }) => {
        state.uploadProfilePicLoading = false;
        state.uploadProfilePicSuccess = true;
        state.userData.profilePictureUrl = payload; // Update the profile picture URL in state
      })
      .addCase(uploadProfilePicture.rejected, (state) => {
        state.uploadProfilePicLoading = false;
        state.uploadProfilePicSuccess = false;
      })
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.fetchLoading = false;
        state.userData = payload;
        state.fetchProfileSuccess = true;
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.loading = false;
        state.fetchLoading = false;
        state.fetchProfileSuccess = false;
      })
      .addCase(verifyUserEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyUserEmail.fulfilled, (state) => {
        state.loading = false;
        state.emailVerificationStatus = true;
      })
      .addCase(verifyUserEmail.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Selector to access user state
export const userSelector = (state) => state.user;

// Export clearUserState action for dispatching
export const { clearUserState, updateUserToken } = userSlice.actions;

const userReducer = userSlice.reducer;
export default userReducer;
