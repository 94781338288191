import { useState } from 'react';
import AgentRegistrationStepOne from '../components/agent-registration-components/AgentRegistrationStepOne';
import AgentRegistrationStepTwo from '../components/agent-registration-components/AgentRegistrationStepTwo';
import AgentRegistrationStepThree from '../components/agent-registration-components/AgentRegistrationStepThree';

const AgentRegistrationPage = () => {
  const [step, setStep] = useState(1);
  const [formDataStepOne, setFormDataStepOne] = useState({});
  const [formDataStepTwo, setFormDataStepTwo] = useState({});
  const [formDataStepThree, setFormDataStepThree] = useState({});

  const handlePreviousStepClick = (stepThreeData) => {
    if (step > 1) {
      setStep(step - 1);
      stepThreeData && setFormDataStepThree(stepThreeData);
    }
  };

  const handleNextStepClick = (data) => {
    console.log({ data });
    step === 1 ? setFormDataStepOne({ ...data }) : setFormDataStepTwo({ ...data });
    setStep(step + 1);
  };

  return (
    <main>
      {step === 1 && (
        <AgentRegistrationStepOne onNextStepClick={handleNextStepClick} formDataStepOne={formDataStepOne} />
      )}
      {step === 2 && (
        <AgentRegistrationStepTwo
          onPreviousStepClick={handlePreviousStepClick}
          onNextStepClick={handleNextStepClick}
          formDataStepOne={formDataStepOne}
          formDataStepTwo={formDataStepTwo}
        />
      )}
      {step === 3 && (
        <AgentRegistrationStepThree
          onPreviousStepClick={handlePreviousStepClick}
          formDataStepOne={formDataStepOne}
          formDataStepTwo={formDataStepTwo}
          formDataStepThree={formDataStepThree}
        />
      )}
    </main>
  );
};

export default AgentRegistrationPage;
