import React, { useEffect, useState } from 'react';
import DashboardRootLayout from '../../components/dashboard-components/DashboardRootLayout';
import { Button } from 'react-bootstrap';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { SlTrash } from 'react-icons/sl';
import { GoPlusCircle } from 'react-icons/go';
import PropertyBox from '../../components/dashboard-components/PropertyBox';
import AdminDocumentRepository from './AdminDocumentRepository';
import utils from '../../utils';
import axios from 'axios';
import EditPropertyModal from '../../components/modal-components/EditPropertyModal';
import SalesRecord from '../../components/dashboard-components/SalesRecord';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSpecificUserProfile, deleteUser } from '../../redux/reducers/adminReducer';
import FullLoader from '../../components/loader-components/Loaders';
import { toast } from 'react-toastify';
// import utils from "../../utils";

const CloseButton = ({ closeToast }) => (
  <button
    className="border-0 bg-danger shadow-sm px-3 py-1 text-white rounded-2 align-self-center ms-2"
    onClick={closeToast}
  >
    Yes
  </button>
);

const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isUploadingToCloudinary, setIsUploadingToCloudinary] = useState(false);
  const [showEditProperties, setShowEditProperties] = useState(false);
  // const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const allProperties = useSelector((state) => state.properties.allProperties) || [];
  const { selectedUser, profileLoading, deleteLoading } = useSelector((state) => state.admin) || {};
  const [editedProperties, setEditedProperties] = useState(allProperties);

  const handleCloudinaryUploader = async (file) => {
    try {
      setIsUploadingToCloudinary(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'castlehub-uploads');

      const response = await axios.post('https://api.cloudinary.com/v1_1/devwillyy/image/upload', formData);
      utils.Toast('Document Uploaded Successfully', 'success');

      if (response.status === 200) {
        const { secure_url: link, original_filename: name } = response?.data;
        console.log(name, link);
        // setUploadedDocuments((prev) => [{ name, link }, ...prev]);
        return { name, link };
      }
    } catch (error) {
      console.error('Error uploading to Cloudinary:', error);
      utils.Toast('Error uploading to Cloudinary', 'error');
      return;
    } finally {
      setIsUploadingToCloudinary(false);
    }
  };

  const handleUploadDocument = async (files) => {
    if (files && files[0]) {
      const file = files[0];
      if (!file) return utils.Toast('No file selected', 'error');

      // Max file size check (example: 2MB)
      if (file.size > 2 * 1024 * 1024) {
        utils.Toast('File size exceeds the 2MB limit.');
        return;
      }

      // const { secure_url: link, original_filename: name } =
      await handleCloudinaryUploader(file);
    }
  };

  const handleDeleteUser = () => {
    toast.warning('Are you sure you want to delete this user?', {
      closeButton: CloseButton,
      onClose: () => {
        dispatch(
          deleteUser({
            userId: id,
          })
        ).then((res) => {
          if (res.type === 'deleteUserProfile/fulfilled') {
            utils.Toast('Deleted Successfully', 'success');
            setTimeout(() => {
              window.location.replace('/admin-dashboard');
            }, 100);
          }
        });
      },
      autoClose: false,
    });
  };

  useEffect(() => {
    dispatch(getSpecificUserProfile({ userId: id }));
  }, [dispatch, id]);

  return (
    <DashboardRootLayout>
      <section className="py-md-3 px-md-2 w-100 py-4">
        {profileLoading ? (
          <FullLoader loaderText="Fetching User Details" isColored />
        ) : deleteLoading ? (
          <FullLoader loaderText="" />
        ) : (
          <>
            <button
              onClick={() => window.history.back()}
              className="mb-4 bg-transparent border-0 d-flex gap-3 align-items-center fw-semibold"
              style={{ color: '#656565' }}
            >
              <IoIosArrowRoundBack size={24} />
              <span>Go back</span>
            </button>
            <div className="d-flex gap-4 align-items-center mb-5">
              <img
                src={selectedUser?.profilePictureUrl}
                alt="profile pic"
                width={160}
                height={160}
                className="rounded-circle img-"
              />
              <div className="d-flex flex-column gap-2">
                <h2 className="fs-3 fw-bold mb-0 main-text-black">
                  {`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                </h2>
                <p className="grey-text mb-0">{selectedUser?.email}</p>
                <button
                  className="border-0 bg-transparent shadow-sm d-flex gap-3 p-2 w-fit align-items-center"
                  style={{ color: '#FF111E' }}
                  onClick={handleDeleteUser}
                >
                  <SlTrash size={16} role="button" />
                  <span>Delete User</span>
                </button>
              </div>
            </div>
            <section>
              {selectedUser?.role === 'agent' ? (
                <SalesRecord />
              ) : (
                <>
                  <header className="d-flex justify-content-between mb-3 align-items-center">
                    <h3 className="main-text-black fs-4 mb-0">Properties ({selectedUser?.propertiesOwned?.length})</h3>
                    <Button
                      className="d-flex gap-2 align-items-center border-0"
                      style={{ color: '#656565', backgroundColor: '#3D3D3D1A' }}
                      onClick={() => setShowEditProperties(true)}
                    >
                      <GoPlusCircle size={16} />
                      <span>Add/Remove Property</span>
                    </Button>
                  </header>
                  <main className="d-flex gap-3 overflow-x-scroll no-scroll mx-2">
                    {selectedUser?.propertiesOwned?.length ? (
                      selectedUser?.propertiesOwned?.length.map(
                        ({ area, location, name, picture, quantity, price }) => (
                          <PropertyBox
                            key={location + area}
                            area={area}
                            location={location}
                            logo={picture}
                            name={name}
                            quantity={quantity}
                            price={price}
                          />
                        )
                      )
                    ) : (
                      <p className="mx-auto mt-1">No Properties Available</p>
                    )}
                  </main>
                  <AdminDocumentRepository
                    handleDocumentUpload={handleUploadDocument}
                    isUploading={isUploadingToCloudinary}
                    documents={selectedUser?.documentRepository}
                  />
                  <EditPropertyModal
                    allProperties={editedProperties}
                    setEditedProperties={setEditedProperties}
                    show={showEditProperties}
                    handleClose={() => setShowEditProperties(false)}
                  />
                </>
              )}
            </section>
          </>
        )}
      </section>
    </DashboardRootLayout>
  );
};

export default UserDetails;
