import React from 'react';
import { Col } from 'react-bootstrap';
import { PdfIcon } from '../../assets/icons/Icons';
import { MdOutlineFileDownload } from 'react-icons/md';
import '../../index.css';
import useResize from '../../custom-hooks/useResize';
import utils from "../../utils";
import { SlTrash } from 'react-icons/sl';

const RepoDocumentBox = ({ name, modifiedOn, isAdminPage, link }) => {
    const { width } = useResize();

    return (
        <div className={`d-flex align-items-center justify-content-between w-100 py-3 px-4 fw-medium ${isAdminPage ? "border mb-1 rounded-1" : "border-bottom no-last-border"} border-secondary-subtle text-secondary`}>
            <Col xs={7} className='d-flex align-items-center gap-3'>
                {!isAdminPage && <input type="checkbox" name="" id="" />}
                <PdfIcon size={24} />
                <span>{width < 640 ? utils.truncateText(name) : name}</span>
            </Col>
            {!isAdminPage && <Col xs={4}>
                    {
                        width < 640 ? `${modifiedOn.toDateString()}`
                        : modifiedOn ? `${modifiedOn.toDateString()} ${modifiedOn.toLocaleTimeString()}`
                        : "Wed Aug 21 2024 9:28:12 PM"
                    }
                </Col>
            }
            {!isAdminPage ? (
                    <Col xs={1} className='text-end'>
                        <a href={link} download="">
                            <MdOutlineFileDownload color='#525252' className='text-end' size={24} />
                        </a>
                    </Col>
                ) : (
                    <Col xs={1} className='text-end'>
                        <SlTrash size={24} role="button" />
                    </Col>
                )
            }
        </div>
    )
}

export default RepoDocumentBox;
