import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import iTechLogo from '../assets/images/iTechLogo.svg';
import '../styles/ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [validated, setValidated] = useState(false);

  const handleResetPassword = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      console.log('Email:', email);
      // Add logic to handle password reset
    }
    setValidated(true);
  };

  return (
    <section className="container poppins-font">
      <div className="page-content">
        <div className="col-md-6">
          <img src={iTechLogo} alt="ITech logo" className="img-fluid" />
          <h2 className="mt-3 forgot-password-title">Forgot password?</h2>
          <p className="forgot-password-message">No worries, we’ll send you reset instructions.</p>
          <Form noValidate validated={validated} onSubmit={handleResetPassword}>
            <div className="form-group-container">
              <Form.Group controlId="formBasicEmail" className="custom-input">
                <Form.Label className="label-top fs-6">Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="focus-none"
                />
                <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
              </Form.Group>
            </div>

            <button type="submit" className="custom-button reset-password-button">
              Reset password
            </button>

            <Link
              to="/login"
              className="pt-4 d-flex justify-content-center align-items-center gap-3 text-decoration-none text-itechRed"
            >
              <MdOutlineKeyboardBackspace className="fs-4" />
              <span>Back to login</span>
            </Link>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
