import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { endpoints, altAPIService } from '../../utils/apiService';
import utils from '../../utils';

const initialState = {
  loading: false,
  deleteLoading: false,
  profileLoading: false,
  updateProfileSuccess: false,
  allUsers: [],
  selectedUser: {},
  fetchProfileSuccess: false,
  token: '',
};

// Async thunk for updating user profile
// export const updateUserProfile = createAsyncThunk(
//   'updateUserProfile',
//   async (payload, { rejectWithValue, getState }) => {
//     const { auth } = getState();

//     try {
//       const req = {
//         method: 'put',
//         address: endpoints.profile,
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${auth.token}`,
//         },
//         data: payload?.data,
//       };

//       const { data } = await altAPIService(req.method, req.address, req.headers, req.data);
//       return data;
//     } catch (error) {
//       console.log({ error });
//       return rejectWithValue(utils.getSimplifiedError(error));
//     }
//   }
// );

// Async thunk for fetching specific user profile

export const getSpecificUserProfile = createAsyncThunk('getSpecificUserProfile', async (payload, { rejectWithValue, getState }) => {
  const { auth } = getState();

  try {
    const req = {
      method: 'get',
      address: `${endpoints.allProfiles}/${payload.userId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };

    const { data } = await altAPIService(req.method, req.address, req.headers);
    return data;
  } catch (error) {
    console.log({ error });
    return rejectWithValue(utils.getSimplifiedError(error));
  }
});

// Async thunk for fetching specific user profile
export const deleteUser = createAsyncThunk('deleteUserProfile', async (payload, { rejectWithValue, getState }) => {
  const { auth } = getState();

  try {
    const req = {
      method: 'delete',
      address: `${endpoints.allProfiles}/${payload.userId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };

    const { data } = await altAPIService(req.method, req.address, req.headers);
    return data;
  } catch (error) {
    console.log({ error });
    return rejectWithValue(utils.getSimplifiedError(error));
  }
});

// Async thunk for fetching user profile
export const getAllUsers = createAsyncThunk('getAllUsers', async (_, { rejectWithValue, getState }) => {
  const { auth } = getState();

  try {
    const req = {
      method: 'get',
      address: endpoints.allProfiles,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    };

    const { data } = await altAPIService(req.method, req.address, req.headers);
    return data;
  } catch (error) {
    console.log({ error });
    return rejectWithValue(utils.getSimplifiedError(error));
  }
});

// Create user slice
export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearAdminState: (state) => {
      state.loading = false;
      state.updating = false;
      state.fetchLoading = false;
      state.updateProfileSuccess = false;
      state.fetchProfileSuccess = false;
      state.emailVerificationStatus = false;
      state.adminData = {};
      state.allUsers = [];
    },
    updateAdminToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    //   .addCase(updateUserProfile.pending, (state) => {
    //     state.loading = true;
    //     state.updating = true;
    //     state.updateProfileSuccess = false;
    //   })
    //   .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.updating = false;
    //     state.fetchLoading = false;
    //     state.updateProfileSuccess = true;
    //     state.adminData = { ...state.adminData, ...payload };
    //   })
    //   .addCase(updateUserProfile.rejected, (state) => {
    //     state.loading = false;
    //     state.updating = false;
    //     state.fetchLoading = false;
    //     state.updateProfileSuccess = false;
    //   })
      .addCase(getSpecificUserProfile.pending, (state) => {
        state.profileLoading = true;
      })
      .addCase(getSpecificUserProfile.fulfilled, (state, { payload }) => {
        state.profileLoading = false;
        state.fetchProfileSuccess = true;
        state.selectedUser = payload?.user;
      })
      .addCase(getSpecificUserProfile.rejected, (state) => {
        state.profileLoading = false;
        state.fetchProfileSuccess = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.deleteLoading = false;
        state.selectedUser = {};
      })
      .addCase(deleteUser.rejected, (state) => {
        state.deleteLoading = false;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.fetchLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.fetchLoading = false;
        state.allUsers = payload?.users?.sort((a, b) => a.lastName > b.lastName ? 1 : a.lastName < b.lastName ? -1 : 0);
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.loading = false;
        state.fetchLoading = false;
        state.fetchProfileSuccess = false;
      })
  },
});

// Export clearAdminState action for dispatching
export const { clearAdminState, updateAdminToken } = adminSlice.actions;

const adminReducer = adminSlice.reducer;
export default adminReducer;
