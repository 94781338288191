import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import protectBrandLogo from '../../assets/protect-assets/protect-brand-logo.svg';
import { downloadSvg, navLinksData } from '../../data/protect-page-data';
import utils from '../../utils';

function ProtectNavbar() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary py-4 inter-font">
      <Container>
        <Navbar.Brand className="me-5">
          <Image src={protectBrandLogo} alt="Protect Brand Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100 d-flex align-items-lg-center align-items-xs-center justify-content-lg-between justify-content-xs-start">
            <aside className="d-flex pt-lg-0 pt-sm-3 flex-column flex-lg-row gap-lg-4 gap-sm-1">
              {navLinksData.map((link) => (
                <Nav.Link
                  key={link.id}
                  href={`#${link.href}`}
                  onClick={(e) => utils.handleOnPageNavigation(e, link.href)}
                >
                  {link.text}
                </Nav.Link>
              ))}
            </aside>

            <aside>
              <button className="my-lg-0 my-sm-3 my-3 btn btn-primary rounded-pill protect-btn smaller-text medium-padding d-flex justify-content-center gap-2 btn-outline-light border-0 text-white">
                <span>Download</span>
                <span>{downloadSvg.icon}</span>
              </button>
            </aside>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default ProtectNavbar;
