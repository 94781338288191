import IleriHeroSection from '../components/ileri-page-components/IleriHeroSection';
import IleriPageAboutSection from '../components/ileri-page-components/IleriPageAboutSection';
import IleriEstateAmenities from '../components/ileri-page-components/IleriEstateAmenities';
import IleriScheduleInspectionSection from '../components/ileri-page-components/IleriScheduleInspectionSection';
import IleriFooter from '../components/ileri-page-components/IleriFooter';
import useScrollToTop from '../custom-hooks/useScrollToTop';

const IleriLandingPage = () => {
  useScrollToTop();

  return (
    <main>
      <IleriHeroSection />
      <IleriPageAboutSection />
      <IleriEstateAmenities />
      <IleriScheduleInspectionSection />
      <IleriFooter />
    </main>
  );
};

export default IleriLandingPage;
