import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import 'aos/dist/aos.css';
import AOS from 'aos';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { calendarSvg, ileriFormData } from '../../data/ileri-page-data';
import IleriSectionHeader from './IleriSectionHeader';

const IleriScheduleInspectionSection = () => {
  const [dateValue, setDateValue] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    inspectionDate: null,
  });

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleCalendarClick = () => {
    document.getElementById('dateInput').click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setDateValue(date);
    setFormData({
      ...formData,
      inspectionDate: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const configOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      console.log({ formData });
      const response = await axios.post('anyEndpointUrl', formData, configOptions);

      console.log('Form data successfully submitted:', response.data);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        inspectionDate: null,
      });

      setDateValue(null);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const renderFormFields = () => {
    return ileriFormData.map((field, index) => (
      <Col key={index} xs={12} md={field.type === 'date' ? 12 : 6} className="text-start mt-4">
        <Form.Group className="d-flex flex-column gap-1">
          <Form.Label>
            {field.label} <span className="text-danger">*</span>
          </Form.Label>
          {field.type === 'date' ? (
            <aside className="position-relative ileriDatePicker-wrapper">
              <DatePicker
                id="dateInput"
                className="form-control flex-grow d-inline-flex w-100 input-padding"
                placeholderText={field.placeholder}
                selected={dateValue}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
              />
              <span className="position-absolute" onClick={handleCalendarClick}>
                {calendarSvg.icon}
              </span>
            </aside>
          ) : (
            <Form.Control
              className="input-padding"
              type={field.type}
              placeholder={field.placeholder}
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
            />
          )}
        </Form.Group>
      </Col>
    ));
  };

  return (
    <section className="poppins-font pt-5 my-4">
      <Container>
        <IleriSectionHeader
          btnText="Estate Inspection and Tour"
          h3Text="Plan Your Visit - Schedule Your Estate Tour Today."
        />
      </Container>
      <Container className="py-5 poppins-font d-flex flex-column gap-4">
        <Row className="pt-2 justify-content-center">
          <Col md={9} sm={12} className="ms-md-5 ms-sm-0">
            <Form className="rounded pt-4 pb-5 px-5 semiwhite-bg" onSubmit={handleSubmit}>
              <Row>{renderFormFields().slice(0, 4)}</Row>
              <Row>{renderFormFields().slice(4)}</Row>
              <Row className="justify-content-center mt-3 pt-5 pb-2">
                <Col md={6} sm={12}>
                  <Button className="w-100 input-padding orange-bg orange-border rounded-pill" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IleriScheduleInspectionSection;
