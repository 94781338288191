import { useState, useEffect } from 'react';
import iTechLogo from '../../assets/images/iTechLogo.svg';
import '../../styles/AgentRegistrationStepTwo.css';

const AgentRegistrationStepTwo = ({ onPreviousStepClick, onNextStepClick, formDataStepTwo }) => {
  const [isFullTimeRealtor, setIsFullTimeRealtor] = useState(formDataStepTwo.isFullTimeRealtor || true);
  const [referralCode, setReferralCode] = useState(formDataStepTwo.referralCode || '');
  const [yearsOfExperience, setYearsOfExperience] = useState(formDataStepTwo.yearsOfExperience || '0-1 year');
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

  useEffect(() => {
    const allFieldsFilled = isFullTimeRealtor && yearsOfExperience.trim() !== '';
    setIsNextButtonDisabled(!allFieldsFilled);
  }, [isFullTimeRealtor, yearsOfExperience]);

  const handleNext = (e) => {
    e.preventDefault();
    onNextStepClick({ isFullTimeRealtor, referralCode, yearsOfExperience });
  };

  return (
    <section className="agent-registration-container">
      <img src={iTechLogo} alt="ITech logo" className="agent-registration-logo" />
      <div className="line"></div>
      <div className="agent-registration-content">
        <div className="agent-registration-header">
          <h2 className="agent-registration-title">Professional Information</h2>
          <div className="progress">2 of 3</div>
        </div>
        <form className="form" onSubmit={handleNext}>
          <div className="input-group-page-two">
            <label className="agent-registration-page-two-label">
              Are you a Full-Time Realtor? <span className="required-field">*</span>
            </label>
            <select
              className="agent-registration-input"
              value={isFullTimeRealtor}
              onChange={(e) => setIsFullTimeRealtor(e.target.value)}
              required
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <div className="input-group-page-two">
            <label className="agent-registration-page-two-label">Who referred you?</label>
            <textarea
              className="agent-registration-text-box"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              placeholder="Enter referral code"
            ></textarea>
          </div>
          <div className="input-group-page-two">
            <label className="agent-registration-page-two-label">
              Years of Experience in real estate? <span className="required-field">*</span>
            </label>
            <select
              className="agent-registration-input"
              value={yearsOfExperience}
              onChange={(e) => setYearsOfExperience(e.target.value)}
              required
            >
              <option value="0-1 year">0-1 year</option>
              <option value="2-4 years">2-4 years</option>
              <option value="5+ years">5+ years</option>
            </select>
          </div>
          <div className="agent-registration-buttons-section">
            <button onClick={() => onPreviousStepClick(undefined)} className="agent-registration-previous-button">
              Previous
            </button>
            <button type="submit" className="agent-registration-next-button" disabled={isNextButtonDisabled}>
              Next
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default AgentRegistrationStepTwo;
