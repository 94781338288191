import { useEffect } from 'react';
import { Col, Container, Figure, Image, Row } from 'react-bootstrap';
import 'aos/dist/aos.css';
import AOS from 'aos';
import IleriSectionHeader from './IleriSectionHeader';
import ileriAboutImg from '../../assets/ileri-assets/ileri-about-img.png';

const IleriPageAboutSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="poppins-font pt-5" data-aos="fade-up">
      <Container>
        <IleriSectionHeader btnText="About us" h3Text="Discovering the Essence and Legacy of Ileri Residential." />

        <Row className="px-5">
          <Col xl={6} lg={6} md={12} sm={12} className="line-semilarge">
            <p className="fw-light fs-6 pe-xl-4 pe-lg-4 pe-md-0 pe-0">
              Ileri Residential is a visionary residential estate designed to redefine the standards of estate
              construction. Nestled in a serene and secure gated community, it offers an array of cutting-edge amenities
              for a modern lifestyle. With plot sizes ranging from 300SQM to 500SQM, you have the flexibility to choose
              the perfect space for your dream home.
            </p>
            <p className="fw-light fs-6 pe-xl-4 pe-lg-4 pe-md-0 pe-0">
              Ileri Residential boasts state-of-the-art infrastructure built with green technology to promote
              sustainability. From advanced resident identification systems to round-the-clock surveillance, we
              prioritize your safety and security. Additionally, enjoy recreational facilities such as play areas for
              children and a fully-equipped gym for adults.
            </p>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            <Figure className="d-flex align-items-start">
              <Image src={ileriAboutImg} className="w-100 h-auto" />
            </Figure>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IleriPageAboutSection;
