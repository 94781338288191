import { useNavigate } from 'react-router-dom';
import iTechLogo from '../../assets/images/iTechLogo.svg';

const ItechLoginImage = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex pb-2 align-align-items-center justify-content-center">
      <img src={iTechLogo} alt="ITech logo" onClick={() => navigate('/')} role="button" />
    </div>
  );
};

export default ItechLoginImage;
