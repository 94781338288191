import { Row, Col, Figure, Image, Button } from 'react-bootstrap';
import phoneImg from '../../assets/protect-assets/phone-mockup.png';
import { downloadButtons } from '../../data/protect-page-data';

const ProtectHeroSection = () => {
  return (
    <section id="about" className="container protect-hero-container inter-font py-5" data-aos="fade-right">
      <Row className="overflow-x-hidden justify-content-between text-lg-start text-sm-center text-center">
        <Col lg={7} md={12} sm={12} className="py-5 d-flex flex-column gap-lg-2 gap-sm-4 gap-3">
          <Row className="flex-column gap-1">
            <div className="pt-3">
              <h1 className="fw-bolder largest-text main-text-black">
                Your <span style={{ color: '#0DA0EA' }}>All-in-One</span> Community Management Solution
              </h1>
            </div>
            <div>
              <p className="text-body">Secure, transparent, and sustainable community living at your fingertips.</p>
            </div>
          </Row>
          <Row className="flex-column gap-2">
            <div>
              <h6 className="fs-6 fw-bold primaries">COMING SOON ON</h6>
            </div>
            <div className="d-flex padding-x-smallscreen flex-md-row flex-sm-row flex-column justify-content-lg-start justify-content-sm-center justify-content-center gap-3">
              {downloadButtons.map((button, index) => (
                <Button
                  key={index}
                  variant={button.variant}
                  className="fw-medium protect-btn-secondary btn-padding-lg rounded-pill d-flex gap-2 align-items-center justify-content-center"
                >
                  {button.icon}
                  <span>{button.text}</span>
                </Button>
              ))}
            </div>
          </Row>
        </Col>

        <Col lg={5} md={12} sm={12}>
          <div className="circular-frame pt-3">
            <Figure className="d-flex justify-content-lg-start justify-content-sm-center justify-content-center">
              <Image src={phoneImg} height={450} alt="App Screenshot" />
            </Figure>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default ProtectHeroSection;
