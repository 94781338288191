import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import LandingPage from './pages/LandingPage';
import ClientSignUpPage from './pages/ClientSignUpPage';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ForgotPassword from './pages/ForgotPassword';
import ProtectLandingPage from './pages/ProtectLandingPage';
import IleriLandingPage from './pages/IleriLandingPage';
import HorizonLandingPage from './pages/HorizonLandingPage';
import OTPPage from './pages/OTPPage';
import ClientOverview from './dashboards/client/ClientOverview';
import ClientSettings from './dashboards/client/ClientSettings';
import AgentOverview from './dashboards/agent/AgentOverview';
import AdminPanel from './dashboards/admin/AdminPanel';
import EnterNewPassword from './pages/EnterNewPassword';
import ResetPasswordResponse from './pages/ResetPasswordResponse';
import AgentRegistrationPage from './pages/AgentRegistrationPage';
import AgentSettings from './dashboards/agent/AgentSettings';
import Login from './components/login-page-components/Login';
import UserDetails from './dashboards/admin/UserDetails';

function AppRoute() {
  return (
    <main className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/signup" element={<ClientSignUpPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<OTPPage />} />
          <Route path="/enter-new-password" element={<EnterNewPassword />} />
          <Route path="/reset-password-response" element={<ResetPasswordResponse />} />
          <Route path="/agent-registration" element={<AgentRegistrationPage />} />
          <Route path="/protect" element={<ProtectLandingPage />} />
          <Route path="/ileri" element={<IleriLandingPage />} />
          <Route path="/horizon" element={<HorizonLandingPage />} />
          <Route path="/admin-dashboard" element={<AdminPanel />} />
          <Route path="/admin-dashboard/user/:id" element={<UserDetails />} />
          <Route path="/client-dashboard" element={<ClientOverview />} />
          <Route path="/client-dashboard/settings" element={<ClientSettings />} />
          <Route path="/agent-dashboard" element={<AgentOverview />} />
          <Route path="/agent-dashboard/settings" element={<AgentSettings />} />
        </Routes>
      </Router>
    </main>
  );
}

export default AppRoute;
