import { useEffect, useState } from 'react';
import '../styles/LandingPage.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Link, useNavigate } from 'react-router-dom';
import iTechLogo from '../assets/images/iTechLogo.svg';
import heroBg from '../assets/images/HeroBg.png';
import team from '../assets/images/team.png';
import TopNav from '../components/globals/TopNav';
import { Carousel, Col, Container, Figure, Image, Modal, OverlayTrigger, Ratio, Row, Tooltip } from 'react-bootstrap';
import {
  heroSectionBtns,
  teamMembers,
  protectInfo,
  firstFooterLinks,
  secondFooterLinks,
  exclusiveProducts,
  testimonialsData,
  contactData,
  ourImpactData,
} from '../data/landing-page-data';
import useScrollToTop from '../custom-hooks/useScrollToTop';
import { FaStar } from 'react-icons/fa';

const LandingPage = () => {
  const [show, setShow] = useState(false);
  const [currentMember, setCurrentMember] = useState({});

  const navigate = useNavigate();

  useScrollToTop();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <main className="itech-landing-page poppins-font">
      <TopNav />

      <section data-aos="fade-up">
        <Container
          fluid
          className="mx-auto hero-section d-flex align-items-center justify-content-center position-relative text-center text-white mt-2 rounded-4 pb-5 pt-5 pt-md-1 pb-md-3"
          style={{
            backgroundImage: `url(${heroBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Row className="justify-content-center align-items-center pt-4 pb-5 py-md-0">
            <Col xs={12} lg={8} className="mx-auto text-center">
              <h1 className="display-4 fw-semibold mb-3">Affordable living meets technological innovation</h1>
              <div className="d-flex justify-content-center">
                <p className="w-100 lead text-white fs-5 small-text lh-lscreen line-medium px-4">
                  We've made real estate accessible to everyone through a secure and straightforward approach, ensuring
                  profitable outcomes for all.
                </p>
              </div>
            </Col>
            <Col xs={10} md={8} lg={6} className="text-white">
              <div className="d-flex align-items-start justify-content-center gap-3">
                {heroSectionBtns?.map((btn) => (
                  <button
                    key={btn.id}
                    onClick={() => navigate(btn.route)}
                    className={`text-nowrap text-white homeHeroButtons ${btn.classes}`}
                  >
                    {btn.text}
                  </button>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className="pt-4 pt-md-5 pb-0 pb-md-5" data-aos="fade-up">
        <Row className="position-relative mt-0 py-5">
          <Col xs={12} className="mx-auto text-center">
            <h1 className="max-width-h1 mx-auto px-lg-5 display-8 fw-semibold mb-3">
              At iTech Homes and Properties, we redefine real estate by making affordable living a reality.
            </h1>
            <div className="d-flex justify-content-center">
              <h2 className="mx-auto three-quarterW fs-5 small-text neutral-text line-larger fw-normal pb-0 px-4">
                Our commitment goes beyond providing affordable living spaces; we integrate cutting-edge technology to
                enhance community living.
              </h2>
            </div>
            <Figure className="d-flex align-items-center justify-content-center mb-4" data-aos="fade-up">
              <Image src={team} alt="Frame" fluid className="w-100" style={{ minHeight: '340px' }} />
            </Figure>
          </Col>
        </Row>
      </Container>

      <section className="pink-bg pt-5">
        <Container className="pt-3 pb-5" data-aos="fade-up">
          <Row className="position-relative mt-0 pb-5">
            <Col xs={12} className="mx-auto text-center">
              <h1 className="max-width-h1 mx-auto px-lg-5 display-8 fw-bold mb-3">Our exclusive estates</h1>
              <div className="d-flex justify-content-center">
                <h2 className="mx-auto three-quarterW fs-5 small-text neutral-text line-larger fw-normal pb-0 px-4">
                  Explore unparalleled investment opportunities that redefine the standards of innovation and quality in
                  the world of real estate.
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="g-4 mt-0 pt-0" data-aos="fade-up">
            {exclusiveProducts.map((member, index) => (
              <Col key={index} xs={12} md={6} lg={4} className="mt-0 pt-0 text-center position-relative hover-effect">
                <div className="position-relative">
                  <img src={member.src} alt={member.alt} className="img-fluid rounded-4" />
                  <div className="overlay d-flex flex-column align-items-center justify-content-end position-absolute top-0 left-0 w-100 h-100 rounded-3 text-white">
                    <h6 className="fs-5 small-text">{member.projectName}</h6>
                    <p className="fw-light xsmall-text">{member.city}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <Container className="py-5" data-aos="fade-up">
        <Row className="position-relative mt-0 pt-5 pb-2 pb-md-5">
          <Col xs={12} className="mx-auto text-center">
            <h1 className="max-width-h1 mx-auto px-lg-5 display-8 fw-semibold mb-3">
              We are committed to providing unique experiences for our clients
            </h1>
          </Col>
        </Row>
        <Row className="mt-4 pb-5 pb-md-0" data-aos="fade-up">
          <Col xs={12} className="d-flex justify-content-center">
            <Ratio aspectRatio="16x9" className="w-100">
              <iframe
                src="https://www.youtube.com/embed/XRSnbHMAh0k?si=O8vry-PkJ-Tx2jUM"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className="w-100 h-100 rounded-4"
              ></iframe>
            </Ratio>
          </Col>
        </Row>
      </Container>

      <section className="pink-bg">
        <Container className="meet-the-team-container" data-aos="fade-up">
          <div className="text-center mb-0" data-aos="fade-up">
            <h1 className="text-dark mb-4 fw-bold">Meet our team</h1>
            <h2 className="mx-auto three-quarterW fs-5 small-text neutral-text line-larger fw-normal pb-0 px-4">
              Meet the passionate individuals behind iTech Homes and Properties, dedicated to making your homeownership
              journey seamless and enjoyable.
            </h2>
          </div>
          <Row className="g-4 mt-0 pt-0" data-aos="fade-up">
            {teamMembers?.map((member, index) => (
              <Col
                key={index}
                xs={12}
                md={6}
                lg={4}
                className="mt-0 pt-0 text-center"
                role="button"
                onClick={() => {
                  setCurrentMember(member);
                  setShow(true);
                }}
              >
                <OverlayTrigger placement="top" overlay={<Tooltip>Click on image to view profile</Tooltip>}>
                  <div className="mt-0 pt-0 position-relative">
                    <img src={member.src} alt={member.alt} className="img-fluid" />
                  </div>
                </OverlayTrigger>
              </Col>
            ))}
          </Row>

          <Modal show={show} onHide={() => setShow(false)} centered className="poppins-font">
            <Modal.Header closeButton>
              <Modal.Title>{currentMember.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentMember.description?.map((item) => (
                <p key={item.id}>{item.text}</p>
              ))}
            </Modal.Body>
          </Modal>
        </Container>
      </section>

      <Container className="mt-4 pt-5 mx-auto" data-aos="fade-up">
        <Carousel
          nextIcon={<span className="carousel-control-next-icon custom-carousel-icon rounded-circle" />}
          prevIcon={<span className="carousel-control-prev-icon custom-carousel-icon rounded-circle" />}
        >
          {testimonialsData.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <Row className="align-items-center">
                <Col xs={12} md={4} className="d-flex justify-content-start justify-content-md-center">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="img-fluid rounded shadow"
                    style={{ minWidth: '100%', maxWidth: '100%' }}
                  />
                </Col>

                <Col xs={12} md={8} className="mt-2 mt-md-0 ps-3">
                  <div className="d-flex align-items-center mb-2">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <FaStar key={i} className="text-warning" />
                    ))}
                  </div>
                  <p className="pt-2 lead text-dark small-text line-medium pe-0 pe-md-2 pe-lg-5">{testimonial.text}</p>
                  <p className="text-dark">— {testimonial.name}</p>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>

      <section className="my-5 py-4" data-aos="fade-up">
        <Container className="py-5 pink-bg rounded-3">
          <Row className="py-5 text-center gap-5 gap-md-0">
            {ourImpactData.map((item, index) => (
              <Col key={index} xs={12} md={4} data-aos="fade-up">
                <h1 className="largest-text text-itechRed fw-bold">{item.value}</h1>
                <p className="lead text-dark small-text line-medium">{item.label}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <Container className="mx-auto" data-aos="fade-up">
        <Row className="align-items-start">
          <Col xs={12} md={6} className="text-center mb-4 mb-md-0 d-flex flex-column justify-content-start">
            <Image src={protectInfo.text} alt="PROtect-Text" className="img-fluid mb-3" />
            <Link to="/protect" className="d-flex justify-content-start">
              <Image src={protectInfo.button} alt="PROtect-Button" className="img-fluid" />
            </Link>
          </Col>
          <Col xs={12} md={6} className="text-center">
            <Image src={protectInfo.image} alt="PROtect" className="img-fluid" />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className="py-5 mx-auto contact-us-section-wrapper"
        style={{ backgroundColor: '#FFF6F6' }}
        data-aos="fade-up"
      >
        <section className="pt-5 pb-1 pb-md-3" id="our-contact">
          <Row className="text-center mb-4">
            <Col>
              <h6 className="text-danger">Contact us</h6>
              <h1 className="text-center fw-bold mb-0 py-2">Get in touch</h1>
              <p className="text-muted">Our friendly team is always here to chat.</p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {contactData.map((item, index) => (
              <Col key={index} xs={12} md={4} className="mb-5 mb-md-4 pb-3 pb-md-0 text-center" data-aos="fade-up">
                <div
                  className="py-3 rounded-circle mb-3 contact-icon-wrapper"
                  style={{ backgroundColor: '#FFEFEF', display: 'inline-block' }}
                >
                  {item.icon}
                </div>
                <h5 className="font-weight-bold">{item.title}</h5>
                <p className="text-muted font-weight-light">{item.description}</p>
                <a href={item.link} className="d-block text-danger text-decoration-none font-weight-bold">
                  {item.contactInfo}
                </a>
              </Col>
            ))}
          </Row>
        </section>
      </Container>

      <footer className="footer" data-aos="fade-up">
        <div className="container">
          <img src={iTechLogo} alt="iTech Logo" className="footer-logo mb-5 mb-md-0" />
          <div className="first-footer-section pb-4 pb-md-0" data-aos="fade-up">
            {firstFooterLinks.map((link) => (
              <Link key={link.to} to={link.to} className="footer-link me-2">
                {link.label}
              </Link>
            ))}
          </div>
          <div className="second-footer-section">
            <h3>&copy; {new Date().getFullYear()}, iTech Homes and Properties. All rights reserved.</h3>
            <div className="second-footer-section-links">
              {secondFooterLinks.map((link) => (
                <Link key={link.to} to={link.to} className="footer-link me-2">
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default LandingPage;
