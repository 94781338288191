import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { endpoints, altAPIService } from '../../utils/apiService';
import utils from '../../utils';
import { propertiesArray } from '../../data/dashboard-data';

const initialState = {
  updating: false,
  fetchLoading: false,
  updateProfileSuccess: false,
  allProperties: propertiesArray
};

// Async thunk for updating user profile
// export const updateUserProfile = createAsyncThunk(
//   'updateUserProfile',
//   async (payload, { rejectWithValue, getState }) => {
//     const { auth } = getState();

//     try {
//       const req = {
//         method: 'put',
//         address: endpoints.profile,
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${auth.token}`,
//         },
//         data: payload?.data,
//       };

//       const { data } = await altAPIService(req.method, req.address, req.headers, req.data);
//       return data;
//     } catch (error) {
//       console.log({ error });
//       return rejectWithValue(utils.getSimplifiedError(error));
//     }
//   }
// );

// // Async thunk for fetching user profile
// export const getUserProfile = createAsyncThunk('getUserProfile', async (_, { rejectWithValue, getState }) => {
//   const { auth } = getState();

//   try {
//     const req = {
//       method: 'get',
//       address: endpoints.profile,
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${auth.token}`,
//       },
//     };

//     const { data } = await altAPIService(req.method, req.address, req.headers);
//     return data;
//   } catch (error) {
//     console.log({ error });
//     return rejectWithValue(utils.getSimplifiedError(error));
//   }
// });

// export const verifyUserEmail = createAsyncThunk('verifyUserEmail', async (payload, { rejectWithValue }) => {
//   try {
//     const { data } = await altAPIService.post(`${endpoints.verifyUserEmail}?${payload}`);
//     return data;
//   } catch (error) {
//     return rejectWithValue(utils.getSimplifiedError(error));
//   }
// });

// Create user slice
export const propertySlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    updateProperties: (state, action) => {
      console.log(action.payload);
      
      // state.allProperties = action.payload;
    },
    // savePropertiesToDBB: (state, action) => {

    // },
    fetchFromDataBase: (state, action) => {
      console.log(action.payload);
      state.fetchLoading = true;
    }
  },
//   extraReducers: (builder) => {
//     builder
//       .addCase(verifyUserEmail.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(verifyUserEmail.fulfilled, (state) => {
//         state.loading = false;
//         state.emailVerificationStatus = true;
//       })
//       .addCase(verifyUserEmail.rejected, (state) => {
//         state.loading = false;
//       });
//   },
});

// // Selector to access user state
// export const propertySelector = (state) => state.allProperties;

// Export clearUserState action for dispatching
export const { fetchFromDataBase, updateProperties } = propertySlice.actions;

const propertyReducer = propertySlice.reducer;
export default propertyReducer;
