import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { formatNumberToCurrency } from '../../utils';
import EditSalesRecordModal from '../modal-components/EditSalesRecordModal';

const SalesRecord = () => {
  const [salesRecords, setSalesRecords] = useState({
    directSales: formatNumberToCurrency(6080000),
    commision: formatNumberToCurrency(1216000),
  });

  const [openEditRecordsModal, setopenEditRecordsModal] = useState(false);

  return (
    <div>
      <h4 className="pb-2 border-bottom border-secondary-subtle px-2 text-main-black">Sales Record</h4>
      <Row className="px-1 my-3 align-items-center">
        <Col xs={3}>
          <Form.Label className="mb-0" htmlFor="directSales">
            Total Direct Sales
          </Form.Label>
        </Col>
        <Col xs={9} className="d-flex gap-5">
          <input
            type="text"
            value={salesRecords?.directSales}
            id="directSales"
            className="px-2 flex-fill"
            // onChange={(e) => setSalesRecords(prev => ({ ...salesRecords, "directSales": e.target.value }))}
            disabled
          />
          <button className="itechRed-bg px-4 py-1 rounded-2 me-3" onClick={() => setopenEditRecordsModal(true)}>
            Edit Direct Sales
          </button>
        </Col>
      </Row>
      <Row className="px-1 mb-3 align-items-center">
        <Col xs={3}>
          <Form.Label className="mb-0" htmlFor="commision">
            Total Commission
          </Form.Label>
        </Col>
        <Col xs={9} className="d-flex gap-5">
          <input
            type="text"
            value={salesRecords?.commision}
            id="commision"
            className="px-2 flex-fill"
            // onChange={(e) => setSalesRecords(prev => ({ ...salesRecords, "commision": e.target.value }))}
            disabled
          />
          <button className="itechRed-bg px-4 py-1 rounded-2 me-3" onClick={() => setopenEditRecordsModal(true)}>
            Edit Commission
          </button>
        </Col>
      </Row>
      <EditSalesRecordModal show={openEditRecordsModal} handleClose={() => setopenEditRecordsModal(false)} />
    </div>
  );
};

export default SalesRecord;
