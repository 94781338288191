import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import 'aos/dist/aos.css';
import AOS from 'aos';
import AccordionContainer from '../../components/globals/AccordionContainer';

function ProtectFaqsSection() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="how-it-works" className="py-5 inter-font grey-bg" data-aos="fade-up">
      <Row className="py-3 container mx-auto gap-5">
        <Col sm={12}>
          <aside className="text-center d-flex flex-column gap-3">
            <h3 className="fw-bolder fs-1 deep-grey-text">Frequently asked questions</h3>
            <p className="grey-text">Everything you need to know about the product and billing.</p>
          </aside>
        </Col>
        <Col sm={12}>
          <AccordionContainer />
        </Col>
      </Row>
    </section>
  );
}

export default ProtectFaqsSection;
