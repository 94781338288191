import { MdContentCopy } from 'react-icons/md';
import { Row, Col, Figure, Card, Button, Image } from 'react-bootstrap';
import DashboardRootLayout from '../../components/dashboard-components/DashboardRootLayout';
import { useRef, useState, useEffect } from 'react';
import { getAgentOverviewData } from '../../data/dashboard-data';
import { TbCurrencyNaira } from 'react-icons/tb';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { TbArrowsUpDown } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import utils from '../../utils';
import DashboardHeader from '../../components/globals/DashboardHeader';
import useResize from '../../custom-hooks/useResize';
import FullLoader from '../../components/loader-components/Loaders';
import useCopyToClipboard from '../../custom-hooks/useCopyToClipboard';

const AgentOverview = () => {
  const { width } = useResize();
  const { user } = useSelector((state) => state.user.userData) || {};
  const { fetchLoading } = useSelector((state) => state.user) || {};

  const referralLinkRef = useRef(null);
  const { isCopied, copyError, copyToClipboard, resetCopyState } = useCopyToClipboard();

  const [agentOverviewData, setAgentOverviewData] = useState([]);

  useEffect(() => {
    if (user) {
      setAgentOverviewData(getAgentOverviewData(user));
    }
  }, [user]);

  const handleCopy = () => {
    if (referralLinkRef.current) {
      copyToClipboard(referralLinkRef.current.textContent || '');
    }
  };

  useEffect(() => {
    if (isCopied) {
      console.log('Text copied to clipboard!');
      utils.Toast('Text copied to clipboard!', 'info');
    }
    if (copyError) {
      console.error(copyError);
    }

    return () => {
      resetCopyState();
    };
  }, [isCopied, copyError, resetCopyState]);

  return (
    <DashboardRootLayout>
      <section className="pb-5 moresmaller-text">
        <Row>
          {fetchLoading ? (
            <FullLoader loaderText="Fetching Agent Profile" isColored />
          ) : (
            <>
              <DashboardHeader title="Agent Overview" />
              <Col md={12} className="px-3 mb-4 py-2">
                <aside>
                  <div>
                    <h3 className="inter-font fs-3 line-larger">{width < 768 ? 'Agent Overview' : 'Overview'} </h3>
                    <p>Your referral code and downlines</p>
                  </div>
                  <Row className="g-4">
                    {agentOverviewData?.map(({ id, text, title, isMonetary, changeType, changePercent }) => (
                      <Col key={id} xs={12} md={3} className="d-flex">
                        <Card className="w-100 flex-fill">
                          <Card.Body>
                            <Card.Title className="mb-1 mt-0 moresmaller-text">{text}</Card.Title>
                            <Card.Text className="mt-1 mb-0 small-text">
                              <span className="d-flex align-items-center">
                                {isMonetary && <TbCurrencyNaira size={21} />}
                                {title}
                              </span>
                              <span className="pt-1 d-flex align-items-center justify-content-end moresmaller-text">
                                {changeType === 'increment' ? (
                                  <IoIosArrowRoundUp size={21} className="text-success pe-1" />
                                ) : changeType === 'decrement' ? (
                                  <IoIosArrowRoundDown size={21} className="text-danger pe-1" />
                                ) : changeType === 'constant' ? (
                                  <TbArrowsUpDown size={20} className="text-warning pe-1" />
                                ) : null}
                                {changePercent && `${changePercent} %`}
                              </span>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <div className="shadow rounded-2 py-3 ps-3 pe-4 mt-3">
                    <h5 className="small-text">Your referral code</h5>
                    <Row className="shadow w-100 ms-1 me-4 rounded-2 mt-2 py-2 ps-2 pe-0">
                      <Col className="d-flex justify-content-between align-items-center">
                        <span ref={referralLinkRef}>{user?.referralId}</span>
                        <span className="copy-icon">
                          <Button onClick={handleCopy} className="copy-btn text-danger d-flex gap-1" variant="button">
                            <MdContentCopy size={21} />
                            <small className="text-decoration-none text-danger text-nowrap">Copy text</small>
                          </Button>
                        </span>
                      </Col>
                    </Row>
                  </div>
                </aside>
              </Col>
              <Col md={12} className="px-3">
                <aside>
                  <div className="mb-3">
                    <h3 className="inter-font fs-3 line-larger">Your downlines</h3>
                  </div>
                  <Row>
                    {user?.downlines && Object.keys(user?.downlines).length > 0 ? (
                      user.downlines.map((downline, index) => (
                        <Col key={index} lg={3} md={6} sm={12} className="mb-3">
                          <Card>
                            <Figure className="pt-3 d-flex justify-content-center align-items-center">
                              <Image
                                variant="top"
                                src={downline.profilePictureUrl}
                                alt={`Profile picture for downline ${index + 1}`}
                                width="50"
                                height="50"
                                className="rounded-circle"
                              />
                            </Figure>
                            <Card.Body className="text-center">
                              <Card.Title className="small-text">{`${downline.firstName} ${downline.lastName}`}</Card.Title>
                              <Card.Text className="moresmaller-text">{downline.email}</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))
                    ) : (
                      <Col xs={12}>
                        <Card.Body className="mb-3 shadow p-4 rounded-3 mx-1">
                          No downlines have registered through you at this time
                        </Card.Body>
                      </Col>
                    )}
                  </Row>
                </aside>
              </Col>
            </>
          )}
        </Row>
      </section>
    </DashboardRootLayout>
  );
};

export default AgentOverview;
