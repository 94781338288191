import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Image, Nav, Row } from 'react-bootstrap';
import { getSidebarData } from '../../data/dashboard-data';
import { clearState } from '../../redux/reducers/authReducer';
import iTechLogo from '../../assets/images/iTechLogo.svg';
import utils from '../../utils';

const DashboardDefaultSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = window.location;
  const { userData } = useSelector((state) => state.user);

  const [sidebar, setSidebar] = useState('');

  useEffect(() => {
    setSidebar(getSidebarData(userData?.user?.role));
  }, [userData]);

  const handleAltClick = (label) => {
    if (label === 'logout') {
      dispatch(clearState());
      navigate('/');
    }

    if (label === 'support') {
      utils.sendSupportEmail();
    }
  };

  return (
    <section className="shadow-side position-fixed left-0 top-0 h-100 w-25 d-none d-md-block">
      <Row className="px-0 mx-0 h-100 w-100">
        <Col className="px-0 mx-0 d-flex h-100 w-100 flex-column">
          <Image src={iTechLogo} alt="Company Logo" width="120" className="ms-4 ps-1 mt-3" />
          <Nav className="flex-column h-100 w-100 justify-content-between mt-4">
            <aside className="mb-auto">
              {sidebar?.top?.map((item) => (
                <Nav.Link
                  key={item.id}
                  onClick={() => (item.path ? navigate(item.path) : handleAltClick(item.label))}
                  className={`py-3 ps-4 d-flex align-items-start justify-content-start shadow-md ${
                    pathname === item.path && 'active'
                  }`}
                >
                  <div>{item.icon}</div>
                  <div className="ms-3 text-body">{item.text}</div>
                </Nav.Link>
              ))}
            </aside>

            <aside className="mt-auto mb-5">
              {sidebar?.bottom?.map((item) => (
                <Nav.Link
                  key={item.id}
                  onClick={() => (item.path ? navigate(item.path) : handleAltClick(item.label))}
                  className={`py-3 ps-4 d-flex align-items-start justify-content-start shadow-md ${
                    pathname === item.path && 'active'
                  }`}
                >
                  <div>{item.icon}</div>
                  <div className="ms-3 text-body">{item.text}</div>
                </Nav.Link>
              ))}
            </aside>
          </Nav>
        </Col>
      </Row>
    </section>
  );
};

export default DashboardDefaultSidebar;
