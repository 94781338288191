import drBodeOdulanmi from '../assets/images/DrBodeOdulanmi.svg';
import drSubairToba from '../assets/images/DrSubairToba.svg';
import msTolulopeAdesina from '../assets/images/MsTolulopeAdesina.svg';
import mrOlubodeBoluwatife from '../assets/images/MrOlubodeBoluwatife.svg';
import msElizabethFoloki from '../assets/images/MsElizabethFoloki.svg';
import mrIsmailAkintunde from '../assets/images/MrIsmailAkintunde.svg';
import msChineloAnene from '../assets/images/MsChineloAnene.svg';
import drAbisoKadir from '../assets/images/DrAbisoKadir.svg';
import mrsHafsatYerima from '../assets/images/MrsHafsatYerima.svg';
import horizonCity from '../assets/images/HorizonMegaCity.png';
import ileriExtension from '../assets/images/IleriResidentialsExtension.png';
import ileriResidential from '../assets/images/IleriResidential.png';
import protectText from '../assets/images/PROtectText.svg';
import protectButton from '../assets/images/ProtectButton.svg';
import protect from '../assets/images/PROtect.png';
import email from '../assets/images/Email.svg';
import office from '../assets/images/Office.svg';
import phone from '../assets/images/Phone.svg';
import testimonialImg1 from '../assets/images/TestimonialImg001.png';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const heroSectionBtns = [
  { id: 1, route: '/agent-registration', text: 'Sign up as agent', classes: 'sign-up-button btn btn-primary' },
  { id: 2, route: '/signup', text: 'Get started', classes: 'login-button btn btn-outline-primary' },
];

const topNavLinksData = [
  {
    label: 'Book an Inspection',
    onClick: true,
  },
  {
    label: 'Contact us',
    href: '#our-contact',
    onClick: false,
  },
];

const topNavDropdownItemsData = [
  {
    label: 'Ileri Residentials',
    route: '/ileri',
  },
  {
    label: 'Horizon MegaCity',
    route: '/horizon',
  },
];

const topNavActionButtonsData = [
  {
    label: 'Sign up as agent',
    route: '/agent-registration',
    className: 'sign-up-button btn btn-primary mb-2',
  },
  {
    label: 'Login',
    route: '/login',
    className: 'login-button btn btn-outline-primary',
    marginTop: true,
  },
];

const headings = [
  { id: 1, text: 'At iTech Homes and Properties, we' },
  { id: 2, text: 'redefine real estate by making' },
  { id: 3, text: 'affordable living a reality.' },
];

const exclusiveProducts = [
  { id: 1, src: horizonCity, projectName: 'Horizon Mega City', city: 'Moniya, Ibadan' },
  { id: 2, src: ileriExtension, projectName: 'Ileri Residential Extention', city: 'Ketu, Epe' },
  { id: 3, src: ileriResidential, projectName: 'Ileri Residential', city: 'Ketu, Epe' },
];

const teamMembers = [
  {
    src: drBodeOdulanmi,
    alt: 'Managing Director',
    name: 'Dr. Bode Odulanmi',
    description: [
      {
        id: 1,
        text: 'Dr. Olabode Odunlami is a prolific real estate entrepreneur who is the driving force of iTech homes and properties.',
      },
      {
        id: 2,
        text: 'Olabode is a graduate of estate management with over half a decade of experience in real estate marketing, sales, development and advisory.',
      },
      {
        id: 3,
        text: 'As the Managing Director of iTech homes and properties, Dr. Olabode sets and evolves the strategic direction for the company and its portfolio of offerings, while nurturing a strong leadership team to drive its execution.',
      },
      {
        id: 4,
        text: 'He is also instrumental in preparation of growth strategies for the Company.  Within the first six months of starting his company, he was able to train and galvanise real estate consultants and is currently building a community of over 200 consultants.',
      },
      {
        id: 5,
        text: 'As the top 100 Africa young influential CEO, Dr. Olabode Odunlami has been devoting his entire time, efforts and energy to develop the company in all aspects including analysis of future competition and threats at Global level.',
      },
      {
        id: 6,
        text: 'He is also passionate about infusing tech into the real estate industry.',
      },
    ],
  },
  {
    src: drSubairToba,
    alt: 'Managing Partner',
    name: 'Dr. Subair Toba',
    description: [
      {
        id: 1,
        text: 'Dr. Toba Subair is a highly motivated computer scientist and entrepreneur recently recognized as one of the 100 Most Influential Young Africans by the Pan African Youth Leadership Forum.',
      },
      {
        id: 2,
        text: 'His professional journey began with a strong foundation in computer science from the Oyo State College of Agriculture and Technology. He then transitioned into the business world, excelling in sales leadership. Starting as a Head Salesperson at a popular travel agency in Lagos, his exceptional managerial skills led to a quick promotion. For two years, he effectively managed the company, demonstrating a talent for strategic thinking and customer relations.',
      },
      {
        id: 3,
        text: 'Driven by a passion for entrepreneurship, Dr. Toba ventured out to establish his own business. This experience, combined with his prior sales success, has equipped him with a unique blend of leadership, strategic thinking, and customer-centric skills.',
      },
      {
        id: 4,
        text: 'Committed to innovation and growth, Dr. Toba is eager to leverage his expertise and contribute to the dynamic business landscape.',
      },
    ],
  },
  {
    src: msTolulopeAdesina,
    alt: 'Chief Oerations Officer',
    name: 'Ms. Tolulope Adesina',
    description: [
      {
        id: 1,
        text: "Tolulope Adesina has years of experience in the real estate and construction industries. With a robust background in operations management, strategic planning, and project execution, She plays a pivotal role in driving the company's operational excellence and growth.",
      },
      {
        id: 2,
        text: 'Her ability to streamline processes and deliver projects on time and within budget quickly earned her recognition and advancement within the industry.',
      },
      {
        id: 3,
        text: "Tolulope is responsible for overseeing day-to-day operations, ensuring the efficiency and effectiveness of all business activities. She manages the integration of advanced technologies into the company's operations, enhancing productivity and client satisfaction. Her strategic vision and operational expertise have been instrumental in optimizing workflows, reducing costs, and improving service delivery",
      },
      {
        id: 4,
        text: 'Her commitment to continuous improvement and innovation is evident in her leadership style, which emphasizes collaboration, transparency, and excellence',
      },
      {
        id: 5,
        text: "Under Tolulope's leadership, iTECH HOMES AND PROPERTIES continues to set new benchmarks in operational efficiency and client satisfaction, reinforcing its position as a leader in the real estate sector.",
      },
    ],
  },
  {
    src: mrOlubodeBoluwatife,
    alt: 'Creative Director',
    name: 'Mr. Olubode Boluwatife',
    description: [
      {
        id: 1,
        text: 'Olubode Boluwatife is a Senior Product Designer with extensive experience working on various projects both in Nigeria and internationally. His innovative approach to design and keen eye for detail have made significant contributions to the success of many high-profile projects.',
      },
      {
        id: 2,
        text: 'With a deep understanding of user experience and visual design principles, Olubode has been instrumental in creating intuitive and aesthetically pleasing products that resonate with users. His expertise spans across multiple industries, allowing him to bring a fresh perspective to each project.',
      },
      {
        id: 3,
        text: "Olubode's work is characterized by a strong emphasis on user-centered design, ensuring that the end product not only meets but exceeds user expectations. He leverages cutting-edge design tools and techniques to deliver exceptional results, consistently pushing the boundaries of what is possible.",
      },
      {
        id: 4,
        text: "As a Creative Director at iTECH HOMES AND PROPERTIES, Olubode plays a key role in shaping the visual identity of the company's brand and products. His leadership in design fosters creativity and innovation within the team, driving the development of solutions that enhance user satisfaction and engagement.",
      },
      {
        id: 5,
        text: "Through his dedication to excellence and passion for design, Olubode Boluwatife continues to set new standards in product design, contributing to the company's reputation as a leader in the real estate sector.",
      },
    ],
  },
  {
    src: msElizabethFoloki,
    alt: 'Brand Strategist',
    name: 'Ms. Elizabeth Foloki',
    description: [
      {
        id: 1,
        text: 'Elizabeth is a content creator and a brand strategist. She loves working with brands to improve their digital presence.',
      },
      {
        id: 2,
        text: 'She helps brands tell their stories through engaging visual social content that people will want to read and share',
      },
      {
        id: 3,
        text: 'As an experienced brand strategist, she excels at crafting effective strategies that drive visibility and engagement for brands helping them establish a strong online presence and gaining the attention they deserve.',
      },
      {
        id: 4,
        text: 'Her almost 5 years of social media management experience focuses largely on Facebook, Instagram and LinkedIn vis-a-vis her adept at studying and understanding brands from both a marketing and business perspective has resulted in her ability to work with little need for direct management.',
      },
    ],
  },
  {
    src: mrIsmailAkintunde,
    alt: 'Brand Designer',
    name: 'Mr.  Ismail Akintunde',
    description: [
      {
        id: 1,
        text: "Akintunde Rufai is a seasoned and talented brand designer with a keen eye for details and visual narration. At ITech Homes and Properties, Akintunde plays a pivotal role in optimizing the sales process. He develops visually appealing marketing materials that effectively communicate the value and benefits of the company's properties. His designs not only capture attention but also drive engagement, leading to increased sales and customer satisfaction.",
      },
      {
        id: 2,
        text: "Through his creative solutions, Akintunde ensures that the brand consistently resonates with its target audience. By integrating modern design techniques and strategic marketing insights, he contributes to the company's growth and success in a competitive market.",
      },
    ],
  },
  {
    src: msChineloAnene,
    alt: 'Non-Executive Director 3',
    name: 'Ms. Chinelo Anene',
    description: [
      {
        id: 1,
        text: 'iTECH HOMES AND PROPERTIES is pleased to announce the appointment of Chinelo Anene as a non-executive member of its Board. Operating as a prominent real estate company in Nigeria and beyond Africa, iTECH HOMES AND PROPERTIES specializes in providing comprehensive services throughout the real estate value chain, focusing on marketing, advising, and development to deliver excellence in the sector.',
      },
      {
        id: 2,
        text: 'Chinelo, with her substantial background in real estate, brings a wealth of experience and strategic insight to the company. Her proven track record in navigating the complexities of the real estate sector, combined with a disciplined and results-oriented approach, positions her as a valuable addition to iTECH HOMES AND PROPERTIES.',
      },
      {
        id: 3,
        text: 'Holding a degree in computer science and a postgraduate qualification in educational management, she offers a practical blend of technical acumen and real-world expertise in real estate. Her deep understanding of the real estate landscape and proven ability to drive results make her an ideal addition to the Board.',
      },
      {
        id: 4,
        text: "Her role in the company is poised to enhance governance and contribute significantly to the continued growth and success of iTECH HOMES AND PROPERTIES. Her strategic insight and experience will be invaluable in driving the company's mission and reinforcing its commitment to excellence.",
      },
    ],
  },
  {
    src: drAbisoKadir,
    alt: 'Non Executive Director',
    name: 'Dr. Abiso Kabir',
    description: [
      {
        id: 1,
        text: "Dr. Kabir, a respected entrepreneur and economist, brings a wealth of experience to the company. As Chairman of Wal-Wanne Group, he has shown exceptional leadership in promoting economic diversification and youth empowerment in Nigeria. His efforts have been pivotal in shifting Nigeria's economy away from its reliance on oil, with a strong focus on agricultural development.",
      },
      {
        id: 2,
        text: "Dr. Kabir oversees extensive rice cultivation across Northern Nigeria, including the states of Yobe, Bauchi, Gombe, Adamawa, Jigawa, Plateau, and Kaduna. His initiatives have provided employment for over 13,000 youths in the region. Despite challenges such as the insurgency in North Eastern Nigeria, Dr. Kabir's mechanized farming projects have continued to thrive.",
      },
      {
        id: 3,
        text: "Dr. Kabir's credentials are impressive. He holds a doctorate in Entrepreneurship Management from Ballsbridge University and is a Fellow of various chartered institutions both within Nigeria and internationally. He has served on several committees and attended numerous national and international seminars and workshops. His philanthropic endeavors are equally commendable, particularly through his foundation, the Walwanne Foundation, which supports Internally Displaced Persons (IDPs) in Northern Nigeria.",
      },
      {
        id: 4,
        text: "Industry stakeholders have welcomed the appointment, viewing Dr. Kabir's experience and expertise as significant assets to ITech Homes and Properties. The board is confident that Dr. Kabir's diverse background and substantial experience will provide invaluable insights, driving the company's growth and reinforcing its commitment to excellence.",
      },
    ],
  },
  {
    src: mrsHafsatYerima,
    alt: 'Non Executive Director 2',
    name: 'Mrs. Hafsat Yerima',
    description: [
      {
        id: 1,
        text: "Mrs. Yerima's educational background is impressive, with a Bachelor's degree in Mass Communication and a Master's degree in Conflict, Peace, and Strategic Studies. She has also received certifications in LCIIMC2 and LCCTCVE2, demonstrating her commitment to continuous learning and professional development.",
      },
      {
        id: 2,
        text: 'Throughout her illustrious career, Mrs. Yerima has honed her skills in effective communication, conflict resolution, and strategic planning. Her expertise in these areas has enabled her to make significant contributions to various organizations, including the National Youth Service Corps (NYSC), where she currently serves as Deputy Director',
      },
      {
        id: 3,
        text: "Mrs. Yerima to the board of ITech Homes and Properties is expected to bring fresh perspectives and expertise to the company. Her wealth of experience and knowledge will be invaluable in shaping the company's strategic direction and ensuring its continued growth and success.",
      },
    ],
  },
];

const testimonialsData = [
  {
    image: testimonialImg1,
    text: "As a first-time investor in land, I was initially apprehensive, but iTech's commitment to simplicity, security, and profitability won me over.",
    name: 'Mary Emmanuella',
    rating: 5,
  },
  {
    image: testimonialImg1,
    text: "I've been working with iTech for over a year, and the returns have been consistently impressive. Their platform is user-friendly and reliable.",
    name: 'John Adekunle',
    rating: 4,
  },
  {
    image: testimonialImg1,
    text: 'iTech has made real estate investment accessible to everyone. I love how transparent they are about the entire process.',
    name: 'Esther Mbakwe',
    rating: 5,
  },
  {
    image: testimonialImg1,
    text: 'The customer support at iTech is fantastic. They always go the extra mile to ensure that my investment needs are met.',
    name: 'Samuel Johnson',
    rating: 4,
  },
  {
    image: testimonialImg1,
    text: 'Investing with iTech has been a game-changer for me. The platform is secure, and the returns are much better than I expected.',
    name: 'Chinedu Okeke',
    rating: 5,
  },
  {
    image: testimonialImg1,
    text: "I was skeptical at first, but iTech's track record speaks for itself. My investments have grown steadily, and I feel confident about my financial future.",
    name: 'Grace Eze',
    rating: 4,
  },
  {
    image: testimonialImg1,
    text: 'iTech offers a seamless experience for real estate investors. Their attention to detail and customer satisfaction is unparalleled.',
    name: 'Ifeanyi Nwosu',
    rating: 5,
  },
  {
    image: testimonialImg1,
    text: "I've tried other platforms, but none compare to iTech in terms of ease of use and return on investment. Highly recommended!",
    name: 'Aisha Mohammed',
    rating: 5,
  },
  {
    image: testimonialImg1,
    text: "The transparency and security that iTech provides are what convinced me to invest. I can track my investments in real-time, and it's very reassuring.",
    name: 'Lola Adeyemi',
    rating: 5,
  },
  {
    image: testimonialImg1,
    text: 'iTech has simplified the process of real estate investment for me. I appreciate their commitment to helping investors like myself succeed.',
    name: 'Abubakar Suleiman',
    rating: 4,
  },
];

const ourImpactData = [
  {
    value: '300',
    label: 'Landlords in 2023',
  },
  {
    value: '200%',
    label: 'Average Annual Return on Investments',
  },
  {
    value: '10',
    label: 'Acres of Land Sold in 2023',
  },
];

const contactInfo = [
  { src: email, alt: 'Email' },
  { src: office, alt: 'Office' },
  { src: phone, alt: 'Phone' },
];

const contactData = [
  {
    icon: <FaEnvelope className="contact-icon" />,
    title: 'Email',
    description: 'Our friendly team is here to help',
    contactInfo: 'info@itechproperties.ng',
    link: `mailto:info@itechproperties.ng?cc=bode@itechproperties.ng&subject=${encodeURIComponent(
      'Support Request: Assistance Needed'
    )}&body=${encodeURIComponent(
      `Dear Itech Properties Team,\n\nI would like assistance with the following:\n\n[Please describe your issue here]\n\nBest regards,\n[Your Name]`
    )}`,
  },
  {
    icon: <FaMapMarkerAlt className="contact-icon" />,
    title: 'Office',
    description: 'Come say hello at our office HQ.',
    contactInfo: 'Suite 9, Joygood Mall, Km 27, Lekki-Epe Express Way\nOpposite Fara Park, Sangotedo Lagos',
    link: 'https://www.google.com/maps?q=Suite+9,+Joygood+Mall,+Km+27,+Lekki-Epe+Express+Way,+Opposite+Fara+Park,+Sangotedo+Lagos',
  },
  {
    icon: <FaPhoneAlt className="contact-icon" />,
    title: 'Phone',
    description: 'Mon-Fri from 8am to 6pm.',
    contactInfo: '+234 905 863 0018',
    link: 'tel:+2349058630018',
  },
];

const protectInfo = {
  text: protectText,
  button: protectButton,
  image: protect,
};

const firstFooterLinks = [
  { to: '/ileri', label: 'Our Projects' },
  { to: '/schedule-inspection', label: 'Schedule Inspection' },
  { to: '/careers', label: 'Careers' },
  { to: '/help', label: 'Help' },
  { to: '/privacy-policy', label: 'Privacy' },
];

const secondFooterLinks = [
  { to: '/terms-and-conditions', label: 'Terms' },
  { to: '/privacy-policy', label: 'Privacy' },
  { to: '/cookies', label: 'Cookies' },
];

export {
  heroSectionBtns,
  topNavLinksData,
  topNavDropdownItemsData,
  topNavActionButtonsData,
  headings,
  exclusiveProducts,
  teamMembers,
  testimonialsData,
  contactInfo,
  contactData,
  protectInfo,
  firstFooterLinks,
  secondFooterLinks,
  ourImpactData,
};
