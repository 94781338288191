import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ileriBrandLogo from '../../assets/ileri-assets/ileri-brand-logo.svg';
import { ileriFooterLinksOne, ileriFooterLinksTwo } from '../../data/ileri-page-data';

const IleriFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <section className="poppins-font py-4 orange-bg">
      <Container>
        <Row className="gap-4 pt-3">
          <Col sm={12} className="d-flex justify-content-center">
            <Image src={ileriBrandLogo} alt="Ileri brand logo" />
          </Col>
          <Col sm={12} className="d-flex justify-content-center">
            <ul className="list-unstyled d-flex col-md-12 col-sm-4 text-decoration-none justify-content-center">
              {ileriFooterLinksOne.map((link, index) => (
                <li key={index} className="ms-3">
                  <Link to={link.path} className="text-white text-decoration-none">
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
              <Col md={4} className="pt-3 d-flex align-items-center">
                <span className="mb-3 mb-md-0 text-light">
                  &copy; {currentYear}, Itech Properties. All rights reserved.
                </span>
              </Col>
              <ul className="nav col-md-4 text-decoration-none justify-content-end list-unstyled d-flex">
                {ileriFooterLinksTwo.map((link, index) => (
                  <li key={index} className="ms-3">
                    <Link to={link.path} className="text-white text-decoration-none">
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </footer>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IleriFooter;
