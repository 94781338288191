import { useState, useCallback } from 'react';

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [copyError, setCopyError] = useState(null);

  const copyToClipboard = useCallback(async (text) => {
    try {
      if (!navigator?.clipboard) {
        throw new Error('Clipboard API not supported');
      }
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setCopyError(null);
    } catch (error) {
      setIsCopied(false);
      setCopyError(error.message || 'Failed to copy text');
    }
  }, []);

  const resetCopyState = () => {
    setIsCopied(false);
    setCopyError(null);
  };

  return { isCopied, copyError, copyToClipboard, resetCopyState };
};

export default useCopyToClipboard;
