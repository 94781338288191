import { IoEyeOffSharp, IoEyeSharp } from 'react-icons/io5';
import useVisibility from '../../custom-hooks/useVisibility';

export const VisibilityToggleBtn = ({ isVisible, onClick }) => {
  return (
    <button type="button" className="position-absolute vision-icon bg-transparent border-0" onClick={onClick}>
      {isVisible ? <IoEyeSharp size={20} /> : <IoEyeOffSharp size={20} />}
    </button>
  );
};

export const VisionIcon = () => {
  const { isVisible: isPasswordVisible, toggleVisibility: togglePasswordVisibility } = useVisibility();
  const { isVisible: isPrevPasswordVisible, toggleVisibility: togglePrevPasswordVisibility } = useVisibility();
  const { isVisible: isConfirmPasswordVisible, toggleVisibility: toggleConfirmPasswordVisibility } = useVisibility();

  return {
    type: isPasswordVisible ? 'text' : 'password',
    icon: <VisibilityToggleBtn isVisible={isPasswordVisible} onClick={togglePasswordVisibility} />,
    typePrev: isPrevPasswordVisible ? 'text' : 'password',
    iconPrev: <VisibilityToggleBtn isVisible={isPrevPasswordVisible} onClick={togglePrevPasswordVisibility} />,
    typeConfirm: isConfirmPasswordVisible ? 'text' : 'password',
    iconConfirm: <VisibilityToggleBtn isVisible={isConfirmPasswordVisible} onClick={toggleConfirmPasswordVisibility} />,
  };
};
