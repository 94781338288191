import { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import { calendarSvg, ileriFormData } from '../../data/ileri-page-data';

const ScheduleInspectionModal = ({ isOpen, onClose }) => {
  const [dateValue, setDateValue] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    inspectionDate: null,
  });

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.modal-toggler')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleCalendarClick = () => {
    document.getElementById('dateInput').click();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setDateValue(date);
    setFormData({ ...formData, inspectionDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const configOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      console.log({ formData });
      const response = await axios.post('anyEndpointUrl', formData, configOptions);

      console.log('Form data successfully submitted:', response.data);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        inspectionDate: null,
      });

      setDateValue(null);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const renderFormFields = () => {
    return ileriFormData.map((field, index) => (
      <Col key={index} xs={12} md={field.type === 'date' ? 12 : 6} className="text-start mt-4">
        <Form.Group className="d-flex flex-column gap-1">
          <Form.Label>
            {field.label} <span className="text-danger">*</span>
          </Form.Label>
          {field.type === 'date' ? (
            <aside className="position-relative ileriDatePicker-wrapper">
              <DatePicker
                id="dateInput"
                className="form-control flex-grow d-inline-flex w-100 input-padding"
                placeholderText={field.placeholder}
                selected={dateValue}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
              />
              <span className="position-absolute" onClick={handleCalendarClick}>
                {calendarSvg.icon}
              </span>
            </aside>
          ) : (
            <Form.Control
              className="input-padding"
              type={field.type}
              placeholder={field.placeholder}
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
            />
          )}
        </Form.Group>
      </Col>
    ));
  };

  if (!isOpen) return null;

  return (
    <section
      className="w-full center-absolute zIndex-md position-absolute top-4 pb-4"
      ref={modalRef}
      style={{ minWidth: '330px', maxWidth: '600px' }}
    >
      <div className="py-2  poppins-font d-flex flex-column gap-4">
        <Row className="pt-2 justify-content-center w-100">
          <Col>
            <Form className="rounded pt-4 pb-5 px-5 semiwhite-bg" onSubmit={handleSubmit}>
              <Row>{renderFormFields().slice(0, 4)}</Row>
              <Row className="inspection-page">{renderFormFields().slice(4)}</Row>
              <Row className="justify-content-center mt-3 pt-5 pb-2">
                <Col md={6} sm={12}>
                  <Button className="w-100 input-padding itechRed-bg btn-outline-light rounded-pill" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ScheduleInspectionModal;
