import React from 'react'
import { Modal } from 'react-bootstrap'

const EditSalesRecordModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Body className="rounded-3" style={{ backgroundColor: "#F8F8F8", minWidth: "50%" }}>

        </Modal.Body>
    </Modal>
  )
}

export default EditSalesRecordModal