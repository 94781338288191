import { useState, useEffect, useRef } from 'react';
import DashboardRootLayout from '../../components/dashboard-components/DashboardRootLayout';
import { Form, Button, Container, Row, Col, Image } from 'react-bootstrap';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { MdCancel, MdSave } from 'react-icons/md';
import CountryList from 'react-select-country-list';
import Select from 'react-select';
import TimezoneSelect from 'react-timezone-select';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import utils from '../../utils';
import DashboardHeader from '../../components/globals/DashboardHeader';
import { getUserProfile, updateUserProfile, uploadProfilePicture } from '../../redux/reducers/userReducer';
import { Editor } from '@tinymce/tinymce-react';
import FullLoader, { ClipLoaders } from '../../components/loader-components/Loaders';

const countryOptions = CountryList().getData();

const UserSettings = ({ profileType }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user.userData) || {};
  const { updating, fetchLoading, uploadProfilePicLoading } = useSelector((state) => state.user) || {};

  const [profilePic, setProfilePic] = useState(null);
  const [profilePicChanged, setProfilePicChanged] = useState(false);
  const [userData, setUserData] = useState({
    ...user,
    country: { label: user.country },
    timezone: { label: user.timezone },
  });

  const cancelTokenSource = useRef(axios.CancelToken.source());

  // Track userData changes
  useEffect(() => {
    setUserData({
      ...user,
      country: { label: user.country },
      timezone: { label: user.timezone },
      bio: user?.bio,
    });
  }, [user]);

  // Detect changes in user data
  const detectChanges = () => {
    return (
      userData.firstName !== user.firstName ||
      userData.lastName !== user.lastName ||
      userData.email !== user.email ||
      userData.country.label !== user.country ||
      userData.timezone.label !== user.timezone ||
      userData.bio.trim() !== user.bio.trim() ||
      profilePic !== null
    );
  };

  const handleProfileUpdate = async () => {
    if (!detectChanges()) {
      utils.Toast('Please make an update before saving.', 'error');
      return;
    }

    let updatedProfilePictureUrl = userData.profilePictureUrl;

    // Upload profile picture to Cloudinary
    if (profilePic) {
      if (!profilePicChanged) {
        utils.Toast('The same profile picture cannot be uploaded.', 'error');
        return;
      }

      // @Izima, Do not remove the await in the line below, It serves a purpose, regardless of the warning message!
      const uploadResponse = await dispatch(uploadProfilePicture(profilePic));

      if (uploadResponse.type === 'uploadProfilePicture/fulfilled') {
        updatedProfilePictureUrl = uploadResponse.payload;
      } else {
        utils.Toast('Error uploading to Cloudinary', 'error');
        return;
      }
    }

    const updatedUserData = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      country: userData.country.label,
      timezone: userData.timezone.label,
      profilePictureUrl: updatedProfilePictureUrl,
      bio: userData.bio,
    };

    // Update user profile
    dispatch(
      updateUserProfile({
        data: updatedUserData,
        cancelToken: cancelTokenSource.current.token,
      })
    ).then((response) => {
      if (response.type === 'updateUserProfile/fulfilled') {
        utils.Toast(response?.payload?.message, 'success');
        setProfilePicChanged(false);
        dispatch(getUserProfile());
        setProfilePic(null);
      }
    });
  };

  // Handle profile picture change
  const handleProfilePicChange = async (e) => {
    const file = e.target.files?.[0];

    try {
      if (!file) {
        utils.Toast('No file selected.', 'error');
        return;
      }
      if (file.size > 2 * 1024 * 1024) {
        utils.Toast('File size exceeds the 2MB limit.', 'error');
        return;
      }

      const base64String = await utils.convertToBase64(file);
      const existingImageBase64 = await utils.convertImageUrlToBase64(userData.profilePictureUrl);

      if (base64String === existingImageBase64) {
        utils.Toast('Selected image is identical to the current one. Update with a different image', 'error');
        return;
      }

      setProfilePicChanged(true);
      setProfilePic(file);
      setUserData((prevUserData) => ({
        ...prevUserData,
        profilePictureUrl: base64String,
      }));
    } catch (error) {
      console.error('Error processing the image:', error);
      utils.Toast('Error processing the image. Please try again.', 'error');
    }
  };

  const handleCancel = () => {
    cancelTokenSource.current.cancel('Profile update request canceled.');
    utils.Toast('Profile update canceled.', 'info');
  };

  return (
    <DashboardRootLayout>
      {fetchLoading ? (
        <FullLoader loaderText={`Fetching ${utils.capitalizeFirstLetter(profileType)} Profile`} isColored />
      ) : (
        <>
          <DashboardHeader title="Settings" />
          <Container className="pt-3 pb-5">
            <Row>
              <Col xs={12} md={12} className="mx-auto">
                <Row className="border-bottom pb-4 pb-md-0">
                  <Col xs={12} md={6} className="text-start">
                    <h2 className="mb-1 text-start">Personal Info</h2>
                    <p className="text-start">Update your photo and personal details here.</p>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="d-flex justify-content-start justify-content-md-end gap-3">
                      <Button
                        variant="white"
                        className="shadow border-opacity-50 border-dark-subtle"
                        onClick={handleCancel}
                      >
                        <MdCancel size={20} className="me-2" />
                        Cancel
                      </Button>
                      <Button
                        className="btn-success itechRed-bg d-flex align-items-center"
                        onClick={handleProfileUpdate}
                        disabled={uploadProfilePicLoading || updating}
                      >
                        {uploadProfilePicLoading || updating ? (
                          <>
                            Saving..
                            <ClipLoaders />
                          </>
                        ) : (
                          <span>
                            <MdSave size={20} className="me-2" /> Save
                          </span>
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Form>
                  {/* Name */}
                  <Form.Group className="pb-3 mb-3 mt-4 pt-1 mt-md-4 border-bottom">
                    <Row>
                      <Col xs={12} md={3}>
                        <Form.Label>Name</Form.Label>
                      </Col>
                      <Col xs={12} md={9}>
                        <Form.Control
                          type="text"
                          name="name"
                          value={`${userData?.firstName} ${userData?.lastName}`}
                          onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })}
                          className="py-2"
                          disabled
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {/* Email */}
                  <Form.Group className="border-bottom pt-3 pb-3 mb-3">
                    <Row>
                      <Col xs={12} md={3}>
                        <Form.Label>Email address</Form.Label>
                      </Col>
                      <Col xs={12} md={9}>
                        <Form.Control
                          type="email"
                          name="email"
                          value={userData?.email}
                          onChange={(e) => setUserData({ ...userData, [e.target.name]: e.target.value })}
                          className="py-2"
                          disabled
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {/* Profile Picture */}
                  <Form.Group className="border-bottom pb-3 pt-3 mb-4">
                    <Row>
                      <Col xs={12} md={3}>
                        <Form.Label className="text-start">Your Photo</Form.Label>
                      </Col>
                      <Col xs={12} md={9}>
                        <div>
                          <Image
                            width={100}
                            height={100}
                            src={userData.profilePictureUrl}
                            roundedCircle
                            alt="profile-img"
                          />
                          <div className="mt-3 d-flex gap-3 align-items-center position-relative">
                            <Form.Control
                              type="file"
                              accept="image/*"
                              name="profilePic"
                              onChange={handleProfilePicChange}
                              className="py-2"
                            />
                            <FaCloudUploadAlt size={25} className="position-absolute text-itechRed vision-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>

                  {/* Country */}
                  <Form.Group className="border-bottom pb-3 pt-3 mb-4">
                    <Row>
                      <Col xs={12} md={3}>
                        <Form.Label>Country</Form.Label>
                      </Col>
                      <Col xs={12} md={9}>
                        <Select
                          name="country"
                          options={countryOptions}
                          value={userData.country}
                          onChange={(selectedOption) => setUserData({ ...userData, country: selectedOption })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {/* Timezone */}
                  <Form.Group className="border-bottom pb-3 pt-3 mb-4">
                    <Row>
                      <Col xs={12} md={3}>
                        <Form.Label>Timezone</Form.Label>
                      </Col>
                      <Col xs={12} md={9}>
                        <TimezoneSelect
                          value={userData.timezone}
                          onChange={(selectedTimezone) => setUserData({ ...userData, timezone: selectedTimezone })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  {/* Bio */}
                  <Form.Group className="border-bottom pb-3 pt-3 mb-4">
                    <Row>
                      <Col xs={12} md={3}>
                        <Form.Label>Bio</Form.Label>
                      </Col>
                      <Col xs={12} md={9}>
                        <Editor
                          apiKey="3202j4svt7sxkdeiun10wo5v6lbxydavvmmksvy4mellc0yg"
                          value={userData.bio}
                          onEditorChange={(content) => {
                            setUserData((prevUserData) => ({ ...prevUserData, bio: content }));
                          }}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: 'advlist autolink lists link image charmap print preview anchor',
                            toolbar:
                              'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </DashboardRootLayout>
  );
};

export default UserSettings;
