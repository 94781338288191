import { Button, Col, Row } from 'react-bootstrap';

const IleriSectionHeader = ({ btnText, h3Text }) => {
  return (
    <>
      <Row>
        <Col lg={4} md={6} sm={12}>
          <aside className="d-flex justify-content-start">
            <Button
              variant="transparent"
              className="text-nowrap ms-md-5 ms-sm-0 fw-medium orange-border semicurved-border orange-text px-4 py-2"
            >
              {btnText}
            </Button>
          </aside>
        </Col>
      </Row>
      <Row className="pt-3 mb-4">
        <Col md={8} sm={12} className="ms-md-5 ms-sm-0">
          <aside>
            <h3 className="fs-1 fw-medium main-text-black line-largest">{h3Text}</h3>
          </aside>
        </Col>
      </Row>
    </>
  );
};

export default IleriSectionHeader;
