import { Container, Row, Col, Nav, Image, Button } from 'react-bootstrap';
import { downloadButtons, footerBottomLinksData } from '../../data/protect-page-data';
import protectBrandLogo from '../../assets/protect-assets/protect-brand-logo.svg';

const ProtectFooter = () => {
  return (
    <footer className="pt-5 pb-5 inter-font grey-bg" data-aos="fade-up">
      <Container className="px-md-4 px-4">
        <Row className="px-md-3 justify-content-between gap-lg-0 gap-sm-5 gap-5">
          <Col xl={4} lg={4} md={6} sm={12} xs={12} className="d-flex flex-column gap-0">
            <Nav.Link href="#" className="mb-3 nav-link p-0 text-muted">
              <Image src={protectBrandLogo} alt="Protect Brand Logo" />
            </Nav.Link>
            <p className="text-muted grey-text smaller-text text-left line-medium">
              Download now for a smarter, safer, and more vibrant community living.
            </p>

            <ul className="nav justify-content-start list-unstyled d-flex gap-4">
              {footerBottomLinksData.map(({ id, text, url }) => (
                <li key={id} className="text-body fw-semibold  xsmall-text">
                  <a className="text-muted text-decoration-none" href={url}>
                    {text}
                  </a>
                </li>
              ))}
            </ul>
          </Col>

          <Col xl={3} lg={4} md={5} sm={12} xs={8} className="ps-lg-3 ps-md-0 ps-sm-2 ps-2">
            <div className="mb-3">
              <h6 className="fs-6 fw-bold xsmall-text primaries">COMING SOON ON</h6>
            </div>
            <div className="pt-1 d-flex flex-md-column flex-sm-row flex-row gap-3">
              {downloadButtons.map((button, index) => (
                <Button
                  key={index}
                  variant={button.variant}
                  className="fw-medium protect-btn-secondary btn-padding-lg-custom py-3 w-75 rounded-pill d-flex gap-2 align-items-center justify-content-center"
                >
                  {button.icon}
                  <span className="text-nowrap">{button.text}</span>
                </Button>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default ProtectFooter;
