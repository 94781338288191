import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import session from 'redux-persist/lib/storage/session';
import { APIService } from '../utils/apiService';
import counterReducer from './reducers/counterReducer';
import authReducer from './reducers/authReducer';
import userReducer from './reducers/userReducer';
import adminReducer from './reducers/adminReducer';
import propertyReducer from './reducers/propertiesReducer';

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  user: userReducer,
  admin: adminReducer,
  properties: propertyReducer
});

const persistConfig = {
  key: 'root',
  storage: session,
  whitelist: ['counter', 'auth', 'user', 'admin'], // persisted reducers
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Configure APIService to use the store's state
APIService.interceptors.request.use(function (config) {
  const token = store.getState().auth?.token;
  config.headers.Authorization = token ? `Bearer ${token}` : null;
  return config;
});

export const persistor = persistStore(store);
