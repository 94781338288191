import { SlCloudUpload } from 'react-icons/sl';
import RepoDocumentBox from '../../components/dashboard-components/RepoDocumentBox';
import Dropzone from 'react-dropzone';
import { Loader } from '../../components/loader-components/Loaders';

const AdminDocumentRepository = ({ handleDocumentUpload, isUploading, documents }) => {
  return (
    <section className="my-5 pe-3">
      <h4 className="pb-2 border-bottom border-secondary-subtle px-2 text-main-black">Document Repository</h4>
      <Dropzone acceptedFiles=".pdf, .doc, .docx" multiple={false} onDrop={handleDocumentUpload}>
        {({ getRootProps, getInputProps }) => (
          <section
            {...getRootProps()}
            className="border border-secondary-subtle rounded-3 py-3 px-5 d-flex flex-column gap-2 text-center align-items-center w-fit mx-auto my-5"
          >
            <input {...getInputProps()} />
            <span className="py-1 px-2 border border-dark-subtle w-fit rounded-2">
              <SlCloudUpload size={20} />
            </span>
            {isUploading ? (
              <Loader loaderText="Uploading documents" />
            ) : (
              <>
                <p className="smaller-text mb-0">
                  <span className="text-itechRed fw-semibold">Click to upload</span>{' '}
                  <span className="grey-text">or drag and drop</span>
                </p>
                <p className="xsmall-text grey-text mb-0">PDF, DOC, DOCX File (max. 2MB)</p>
              </>
            )}
          </section>
        )}
      </Dropzone>
      <div className="w-100">
        {documents?.map(({ name, link }) => (
          <RepoDocumentBox isAdminPage={true} name={name} link={link} key={name} />
        ))}
      </div>
    </section>
  );
};

export default AdminDocumentRepository;
